import React, { useEffect, useCallback } from 'react';
import { AuthenticationService } from '@knockrentals/knock-react';
import { LoadingOverlay } from '@knockrentals/knock-shared-web';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { ACCESS_TOKEN_LOGIN_TYPE } from '../../constants';

const LogoutPage = () => {
  const unifiedLoginAuth = useAuth();
  const isAuthenticated =
    AuthenticationService.isAuthenticated() || unifiedLoginAuth.isAuthenticated;

  const unifiedLogout = useCallback(() => {
    return Promise.all([
      unifiedLoginAuth.revokeAccessToken(),
      unifiedLoginAuth.removeUser(),
    ]);
  }, [unifiedLoginAuth.revokeAccessToken, unifiedLoginAuth.removeUser]);

  useEffect(() => {
    if (unifiedLoginAuth.isAuthenticated) {
      unifiedLogout();
    } else {
      localStorage.removeItem(ACCESS_TOKEN_LOGIN_TYPE);
      AuthenticationService.clearToken();
    }
  }, [unifiedLoginAuth.isAuthenticated]);

  if (isAuthenticated) {
    return <LoadingOverlay open />;
  }

  return <Redirect to={{ pathname: '/login' }} />;
};

export default LogoutPage;
