import { validateUrl } from '../../../Utils';
import { PREFERENCE_NAMES } from './constants';

const {
  REQUIRE_LEASE_TERM,
  REQUIRE_PREFERRED_BEDROOM_COUNT,
  REQUIRE_PREFERRED_UNIT,
  REQUIRE_PROSPECT_FLOOR_PLAN,
  REQUIRE_PROSPECT_MOVE_IN_DATE,
  SELF_GUIDED_TOUR_BUTTON_LABEL,
  SELF_GUIDED_TOUR_URL,
  TOURS_EXPORT_ONLY_FAVORITE_UNIT,
} = PREFERENCE_NAMES;

export const INVALID_URL_MESSAGE = 'Specified website is invalid';

export const getInitialTextPreferences = (preferences = {}) => ({
  [SELF_GUIDED_TOUR_BUTTON_LABEL]:
    preferences[SELF_GUIDED_TOUR_BUTTON_LABEL] || '',
  [SELF_GUIDED_TOUR_URL]: preferences[SELF_GUIDED_TOUR_URL] || '',
});

export const getOriginalPropertyPreferences = (propertyPreferences = {}) => ({
  [REQUIRE_LEASE_TERM]: propertyPreferences[REQUIRE_LEASE_TERM],
  [REQUIRE_PREFERRED_BEDROOM_COUNT]:
    propertyPreferences[REQUIRE_PREFERRED_BEDROOM_COUNT],
  [REQUIRE_PREFERRED_UNIT]: propertyPreferences[REQUIRE_PREFERRED_UNIT],
  [REQUIRE_PROSPECT_FLOOR_PLAN]:
    propertyPreferences[REQUIRE_PROSPECT_FLOOR_PLAN],
  [REQUIRE_PROSPECT_MOVE_IN_DATE]:
    propertyPreferences[REQUIRE_PROSPECT_MOVE_IN_DATE],
  [SELF_GUIDED_TOUR_URL]:
    propertyPreferences.preferences?.[SELF_GUIDED_TOUR_URL] || '',
  [SELF_GUIDED_TOUR_BUTTON_LABEL]:
    propertyPreferences.preferences?.[SELF_GUIDED_TOUR_BUTTON_LABEL] || '',
  [TOURS_EXPORT_ONLY_FAVORITE_UNIT]:
    propertyPreferences.preferences?.[TOURS_EXPORT_ONLY_FAVORITE_UNIT],
});

export const validate = (name, value) => {
  if (name === SELF_GUIDED_TOUR_URL && value && !validateUrl(value)) {
    return {
      [SELF_GUIDED_TOUR_URL]: INVALID_URL_MESSAGE,
    };
  }
};
