import React from 'react';
import 'moment-timezone';
import 'moment-duration-format';
import classnames from 'classnames';
import { ToastContainer } from '@knockrentals/knock-react';
import { AdminRouter } from './Navigation/AdminRouter';
import { NavigationBar } from './Navigation/NavigationBar';
import { Notifications } from './Components/Notifications';
import { OmniBarShell } from './Components/OmniBarShell';
import { useAppContext } from './Context/AppContext';
import { useFeatureFlagContext } from './Context/FeatureFlagContext';
import './App.scss';

const App = () => {
  const { isReady, isLoggedIn } = useAppContext();

  const { isOmnibarEnabled } = useFeatureFlagContext();

  if (!isReady) {
    return null;
  }

  return (
    <div
      className={classnames(
        'knock-admin-app',
        isOmnibarEnabled && 'admin-omnibar'
      )}
    >
      {isLoggedIn && isOmnibarEnabled && <OmniBarShell />}

      {isLoggedIn && (
        <div className="knock-admin-app-display-col knock-admin-app-navbar-col">
          <NavigationBar />
        </div>
      )}

      <div className="knock-admin-app-display-col knock-admin-app-content-col">
        <AdminRouter />
      </div>

      <ToastContainer />
      <Notifications />
    </div>
  );
};

export default App;
