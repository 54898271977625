import { useEffect, useState, useRef } from 'react';

import { dripCampaignsApi } from '../../../DripCampaignsApi';
import { useAppContext } from '../../../../../../Context/AppContext';
import { getVirtualAgents } from '../../../../../VirtualAgent/VirtualAgentAPI';
import { useFeatureFlagContext } from '../../../../../../Context/FeatureFlagContext';
import { SELECT_PROPERTY_MODES } from '../constants';

export const getAssociatedPropertiesMap = (associatedProperties) =>
  associatedProperties.reduce((propertiesMap, property) => {
    propertiesMap[property.propertyId] = true;
    return propertiesMap;
  }, {});

export const useProperties = ({
  associatedProperties,
  campaignId,
  selectPropertiesMode,
  setErrorMessage,
  triggerType,
}) => {
  const associatedPropertiesMapRef = useRef(
    getAssociatedPropertiesMap(associatedProperties),
  );
  const [properties, setProperties] = useState([]);
  const [selectedPropertiesMap, setSelectedPropertiesMap] = useState(
    associatedPropertiesMapRef.current,
  );
  const { companyId } = useAppContext();
  const { isAiNurtureEnabled } = useFeatureFlagContext();

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const properties = await dripCampaignsApi.getDripCampaignPropertyList(
          campaignId,
          triggerType,
        );

        if (isAiNurtureEnabled) {
          const { results: virtualAgentConfig } = await getVirtualAgents(
            companyId,
            1,
            1000,
          );

          const propertiesMap = virtualAgentConfig.reduce((map, property) => {
            map[property.property_id] = property.is_ai_nurture_enabled;
            return map;
          }, {});

          const updatedProperties = properties.map((property) => ({
            ...property,
            isAiNurtureEnabled: propertiesMap[property.propertyId] || false,
          }));

          setProperties(updatedProperties);
        } else {
          setProperties(properties);
        }
      } catch (e) {
        setErrorMessage(e.message);
      }
    };
    fetchProperties();
  }, [campaignId, companyId, isAiNurtureEnabled, setErrorMessage, triggerType]);

  const getUpdatedAssociatedProperties = () =>
    properties
      .filter((property) => selectedPropertiesMap[property.propertyId])
      .sort((propertyA, propertyB) =>
        propertyA.propertyName > propertyB.propertyName ? 1 : -1,
      );

  const filterAssociatedProperties = () =>
    properties.filter(
      (property) => !associatedPropertiesMapRef.current[property.propertyId],
    );

  const propertiesList =
    selectPropertiesMode === SELECT_PROPERTY_MODES.EDIT
      ? properties
      : filterAssociatedProperties();

  const getSelectedPropertyCount = () => {
    const selectedPropertiesLength = Object.values(
      selectedPropertiesMap,
    ).filter((isSelected) => isSelected).length;

    if (selectPropertiesMode === SELECT_PROPERTY_MODES.EDIT) {
      return selectedPropertiesLength;
    }

    return selectedPropertiesLength - associatedProperties.length;
  };

  const getHasPropertySelectionChanged = () =>
    Object.entries(selectedPropertiesMap).some(([propertyId, isSelected]) => {
      if (associatedPropertiesMapRef.current.hasOwnProperty(propertyId)) {
        return associatedPropertiesMapRef.current[propertyId] !== isSelected;
      }
      return isSelected;
    });

  return {
    associatedPropertiesMap: associatedPropertiesMapRef.current,
    getUpdatedAssociatedProperties,
    hasPropertySelectionChanged: getHasPropertySelectionChanged(),
    propertiesList,
    selectedPropertiesCount: getSelectedPropertyCount(),
    selectedPropertiesMap,
    setSelectedPropertiesMap,
  };
};
