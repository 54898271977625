import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AiSparkleSvgIcon from '../../../../../Components/Icons/AISparkleSvgIcon';
import AiNurturePropertyTable from './AiNurturePropertyTable';

const useStyles = makeStyles({
  aiNurtureList: {
    background: '#FFFFFF',
    border: '1px solid transparent',
    borderRadius: '8px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(141.28deg, #70D8C3 15.97%, #062679 127.57%)',
    boxShadow: '0px 4px 16px -2px #18274B05, 0px 2px 12px -4px #18274B0A',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxSizing: 'border-box',
    marginBottom: '30px',
  },
  avatar: {
    backgroundColor: 'transparent',
  },
  listItemSecondaryAction: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
  },
});

const AiNurtureContainer = ({
  aiNurtureEnabledPropertyCount,
  aiNurturePropertyData,
  errorMessage,
  isLoading
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const nurturePropertyCount = aiNurturePropertyData.length;
  const AI_NURTURE_HELPER_TEXT = 'Contact your representative for AI Nurture configuration';
  const AI_NURTURE_TITLE = `AI Nurture Campaign is activated for ${aiNurtureEnabledPropertyCount} of ${nurturePropertyCount} eligible properties`

  const toggleOpen = () => setIsOpen((prev) => !prev);

  return (
    <List className={classes.aiNurtureList}>
      <ListItem button onClick={toggleOpen}>
        <ListItemAvatar>
          <Avatar className={classes.avatar} variant="square">
            <AiSparkleSvgIcon />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={AI_NURTURE_TITLE}
          secondary={AI_NURTURE_HELPER_TEXT}
        />

        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <AiNurturePropertyTable aiNurturePropertyData={aiNurturePropertyData} isLoading={isLoading} errorMessage={errorMessage} />
      </Collapse>
    </List>
  );
};

AiNurtureContainer.propTypes = {
  aiNurtureEnabledPropertyCount: PropTypes.number.isRequired,
  aiNurturePropertyData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isAiNurtureEnabled: PropTypes.bool.isRequired,
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

export default AiNurtureContainer;
