import React from 'react';
import PropTypes from 'prop-types';
import { InputText } from '@knockrentals/knock-shared-web';
import { LABELS, PLACEHOLDER_TEXT } from './constants';

const SettingsTextInput = ({
  errorMessages = {},
  handleOnBlur,
  handleOnChange,
  name,
  value,
  ...rest
}) => (
  <div className="settings-text-input-wrapper">
    <InputText
      error={Boolean(errorMessages[name])}
      fullWidth
      helperText={errorMessages[name]}
      label={LABELS[name]}
      name={name}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      placeholder={PLACEHOLDER_TEXT[name]}
      requiredMessage="required"
      value={value || ''}
      {...rest}
    />
  </div>
);

SettingsTextInput.propTypes = {
  errorMessages: PropTypes.objectOf(PropTypes.string),
  handleOnBlur: PropTypes.func,
  handleOnChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
};

export default SettingsTextInput;
