import { useEffect, useState, useCallback } from 'react';
import * as virtualAgentApi from '../../../../VirtualAgent/VirtualAgentAPI';
import { useAppContext } from '../../../../../Context/AppContext';
import { useFeatureFlagContext } from '../../../../../Context/FeatureFlagContext';
import { getPropertyList } from '../../../../../Pages/Features/SchedulingPage/schedulingApi';

const ERROR_MESSAGE = "Error loading AI Nurture property data";

export const useAiNurturePropertyData = () => {
  const { companyId } = useAppContext();
  const { isAiNurtureEnabled } = useFeatureFlagContext();

  const [aiNurturePropertyData, setAiNurturePropertyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [aiNurtureEnabledPropertyCount, setAiNurtureEnabledPropertyCount] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const properties = await getPropertyList();
      const propertyIds = properties.map((prop) => prop.id);

      const responseProducts = await virtualAgentApi.getProductsEnabledForProperties(JSON.stringify(propertyIds));

      const nurtureProductEnabledMap = responseProducts.reduce((productsMap, product) => {
        productsMap[product.property_id] = product.nurture_product_enabled;
        return productsMap;
      }, {});

      const aiNurtureProductEnabledProperties = properties.filter((property) => nurtureProductEnabledMap[property.id]);

      if (aiNurtureProductEnabledProperties.length > 0) {
        const { results: virtualAgents } = await virtualAgentApi.getVirtualAgents(companyId, 1, 1000);

        const virtualAgentMap = virtualAgents.reduce((agentMap, agent) => {
          agentMap[agent.property_id] = agent.is_ai_nurture_enabled;
          return agentMap;
        }, {});

        let activePropertyCount = 0;
        const propertyData = aiNurtureProductEnabledProperties.map((property) => {
          const isAiNurtureEnabled = virtualAgentMap[property.id] || false;
          if (isAiNurtureEnabled) {
            activePropertyCount++;
          }
          return {
            name: property.name,
            isAiNurtureEnabled,
          };
        });

        setAiNurtureEnabledPropertyCount(activePropertyCount);
        setAiNurturePropertyData(propertyData);
      }
    } catch (error) {
      setErrorMessage(ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    if (isAiNurtureEnabled) {
      fetchData();
    }
  }, [fetchData, isAiNurtureEnabled]);

  return {
    aiNurtureEnabledPropertyCount,
    aiNurturePropertyData,
    errorMessage,
    isLoading,
  };
};
