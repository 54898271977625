import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Alert,
  Link,
  makeStyles,
  Text,
  ThemeProvider,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  migrationAlert: {
    padding: '16px',
  },
  photoLink: {
    cursor: 'pointer',
  },
  subTitle: {
    marginLeft: '5px',
  },
});

const MigrationAlert = () => {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <Alert
        className={classes.migrationAlert}
        severity="info"
        variant="outlined"
      >
        <Text>
          Photo management is now available in the new{' '}
          <Link
            className={classes.photoLink}
            color="textPrimary"
            component={RouterLink}
            to="/properties"
            variant="subtitle1"
          >
            Photos
          </Link>{' '}
          tab on the properties page.{' '}
          <Text className={classes.subTitle} display="inline" variant="caption">
            (This page will be deprecated in the future.)
          </Text>
        </Text>
      </Alert>
    </ThemeProvider>
  );
};

export default MigrationAlert;
