import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DynamicNumberInsertion } from '../Doorway/components/DynamicNumberInsertion';
import InputURL from './InputURL';
import InputCustomText from './InputCustomText';
import './PropertyInfoEditCustom.scss';
import { trim } from 'lodash/fp';
import PropertyInfoCheckboxInput from './components/PropertyInfoCheckboxInput';
import PropertyInfoFaqCheckboxInput from './components/PropertyInfoFaqCheckboxInput';
import IncludeInQuickLaunchMenuSection from './components/IncludeInQuickLaunchMenuSection';
import { Tooltip } from '@knockrentals/knock-shared-web';
import InputMask from 'react-input-mask';
import { RichTextEditor } from '../../RichTextEditor';
import { LINK_TARGETS } from '../../RichTextEditor/RichTextEditor';
import { Toaster } from '@knockrentals/knock-react';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import { LeasingProviderOptionsEnum } from './Leasing/constants';
import { validateUrl } from '../../Utils';

export const INVALID_URL_MESSAGE = 'Specified URL is invalid';

const getInitialValue = (val, defaultVal) => {
  return val !== null && val !== undefined ? val : defaultVal;
};

const SCHEDULE_TOUR_CONTENT_BOX_CHAR_LIMIT = 500;

const getIncludeInQuickLaunchDefaults = (doorway) => {
  return [
    {
      name: 'schedule',
      enabled: true,
    },
    {
      name: 'gallery',
      enabled: getInitialValue(doorway.galleryIsActive, true),
    },
    {
      name: 'virtualTour',
      enabled: true,
    },
    {
      name: 'selfGuidedTour',
      enabled: true,
    },
    {
      name: 'availability',
      enabled: getInitialValue(doorway.availabilityIsActive, true),
    },
    {
      name: 'applyNow',
      enabled: getInitialValue(doorway.applyNowIsActive, true),
    },
  ];
};

const getIncludeInQuickLaunchValue = (
  doorway,
  includeInQuickLaunchMenuList
) => {
  const defaultValues = getIncludeInQuickLaunchDefaults(doorway);

  if (!includeInQuickLaunchMenuList) {
    return defaultValues;
  }

  const quickLaunchMenuListMap = includeInQuickLaunchMenuList.reduce(
    (MenuListMap, menuItem) => {
      MenuListMap[menuItem.name] = menuItem;
      return MenuListMap;
    },
    {}
  );

  const missingItems = defaultValues.reduce((items, defaultValue) => {
    if (!quickLaunchMenuListMap[defaultValue.name]) {
      items.push(defaultValue);
    }
    return items;
  }, []);

  return [...includeInQuickLaunchMenuList, ...missingItems];
};

class PropertyInfoEditCustom extends Component {
  refs = {
    form: null,
  };

  state = {
    isDirty: false,
    errors: {},
    editedIndex: null,
    customDetails: [],
    includeInQuickLaunchMenuList: [],
  };

  UNSAFE_componentWillMount() {
    this.setPropertyIntoState(this.props.property);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setPropertyIntoState(nextProps.property);
  }

  shouldApplyNowButtonBeEnabled = (property) => {
    const {
      data: {
        leasing: { provider, application: { link: applicationUrl } = {} } = {},
      } = {},
    } = property;

    if (applicationUrl) {
      return true;
    }
    if (provider === LeasingProviderOptionsEnum.OnSite) {
      return true;
    }
    return false;
  };

  setPropertyIntoState(property) {
    const errors = this.state.errors || {};
    const { data: { doorway = {} } = {} } = property;

    const newState = {
      availabilityIsActive: getInitialValue(doorway.availabilityIsActive, true),
      faqsIsActive: getInitialValue(doorway.faqsIsActive, true),
      galleryIsActive: getInitialValue(doorway.galleryIsActive, true),
      somethingElseIsActive: getInitialValue(
        doorway.somethingElseIsActive,
        true
      ),
      requestTextIsActive: getInitialValue(doorway.requestTextIsActive, true),
      renterPortalIsActive: getInitialValue(doorway.renterPortalIsActive, true),
      leasingSpecialIsActive: getInitialValue(
        doorway.leasingSpecialIsActive,
        false
      ),
      neighborhoodIsActive: getInitialValue(
        doorway.neighborhoodIsActive,
        false
      ),
      applyNowIsActive: getInitialValue(
        doorway.applyNowIsActive &&
          this.shouldApplyNowButtonBeEnabled(property),
        false
      ),
      hideKnockBranding: getInitialValue(doorway.hideKnockBranding, false),
      useDynamicFloorplans: getInitialValue(doorway.useDynamicFloorplans, true),
      leasingInfoIsActive: getInitialValue(doorway.leasingInfoIsActive, false),
      petInfoIsActive: getInitialValue(doorway.petInfoIsActive, false),
      dynamicNumberInsertionType: getInitialValue(
        doorway.dynamicNumberInsertionType,
        ''
      ),
      formattedNumber: getInitialValue(doorway.formattedNumber, ''),
      formattedNumberIsActive: getInitialValue(
        doorway.formattedNumberIsActive,
        false
      ),
      hideUnitPreferences: getInitialValue(doorway.hideUnitPreferences, false),
      hideUnitSelector: getInitialValue(doorway.hideUnitSelector, false),
      hidePricing: getInitialValue(doorway.hidePricing, false),
      limitAvailableUnitsShown: getInitialValue(
        doorway.limitAvailableUnitsShown,
        false
      ),
      showNoteInput: getInitialValue(doorway.showNoteInput, false),
      useCustomWebsite: getInitialValue(doorway.useCustomWebsite, false),
      bannerText: getInitialValue(doorway.bannerText, ''),
      agentGuidedTourDisclaimerText: getInitialValue(
        doorway.agentGuidedTourDisclaimerText,
        ''
      ),
      selfGuidedTourDisclaimerText: getInitialValue(
        doorway.selfGuidedTourDisclaimerText,
        ''
      ),
      liveVideoTourDisclaimerText: getInitialValue(
        doorway.liveVideoTourDisclaimerText,
        ''
      ),
      excludeAppointmentMessagePhone: getInitialValue(
        doorway.excludeAppointmentMessagePhone,
        false
      ),
      customAppointmentMessage: getInitialValue(
        doorway.customAppointmentMessage,
        ''
      ),
      scheduleATourContentBox: getInitialValue(
        doorway.scheduleATourContentBox,
        ''
      ),
      customDetails: getInitialValue(property.data.customDetails, []),
      includeInQuickLaunchMenuList: getIncludeInQuickLaunchValue(
        doorway,
        doorway.includeInQuickLaunchMenuList
      ),
    };

    const doorwayUrlStates = [
      ['agentGuidedTourDisclaimerURL', 'agentGuidedTourDisclaimerText'],
      ['bannerURL', 'bannerText'],
      ['liveVideoTourDisclaimerURL', 'liveVideoTourDisclaimerText'],
      ['residentPortalURL'],
      ['selfGuidedTourDisclaimerURL', 'selfGuidedTourDisclaimerText'],
    ].reduce(
      (urlStates, fields) => {
        const [urlField, associatedTextField] = fields;

        if (!errors[urlField]) {
          urlStates.urls[urlField] = getInitialValue(doorway[urlField], '');
          return urlStates;
        }

        if (associatedTextField && !doorway[associatedTextField]) {
          urlStates.errors[urlField] = '';
          urlStates.urls[urlField] = getInitialValue(doorway[urlField], '');
        }

        return urlStates;
      },
      { urls: {}, errors: {} }
    );

    this.setState((prevState) => {
      const prevErrors = prevState.errors || {};
      return {
        ...newState,
        ...doorwayUrlStates.urls,
        errors: {
          ...prevErrors,
          ...doorwayUrlStates.errors,
        },
      };
    });
  }

  renderCustomQuestion = (customDetail, key) => {
    const questionText = customDetail.title || `Custom Question ${key + 1}`;
    const answerText =
      customDetail.details || `Custom Answer/Details ${key + 1}`;

    return (
      <div key={`custom-detail-${key}`}>
        <div className="property-info-edit-custom-faq-item">
          <input
            type="checkbox"
            checked={customDetail.isActive}
            onChange={this.customDetailChanged(key, 'isActive').bind(this)}
          />
          <div>
            <div>{questionText}</div>
            <div className="property-info-edit-custom-faq-item-answer-text">
              {answerText}
            </div>
          </div>
          <div className="knock-react-flex">
            <i
              className="fa fa-lg fa-trash text-danger ml-2 mr-2"
              onClick={this.removeCustomDetail.bind(this, key)}
            />
            <i
              className="fa fa-lg fa-edit text-info"
              onClick={this.editCustomDetail.bind(this, key)}
            />
          </div>
        </div>

        {this.state.editedIndex === key && (
          <div className="property-info-edit-custom-question-edit-container">
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">Question</div>
              <div className="property-info-edit-field-input">
                <input
                  value={customDetail.title}
                  name={`title-${key}`}
                  onChange={this.customDetailChanged(key, 'title').bind(this)}
                  className="knock-react-input"
                  placeholder="e.g. Any additional pet services?"
                  required
                />
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">
                Answer/Details
              </div>
              <div className="property-info-edit-field-input">
                <textarea
                  value={customDetail.details}
                  name={`details-${key}`}
                  onChange={this.customDetailChanged(key, 'details').bind(this)}
                  placeholder="e.g. Yes, we offer a dog and cat spa along with..."
                  required
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderSelfGuidedSection = () => {
    return (
      <div className="property-info-edit-field-container">
        <hr />
        <h3>Tour Disclaimer Settings</h3>

        <div className="property-info-edit-custom-item">
          <div>Agent Guided Tour</div>
          <div>
            <InputCustomText
              textAreaTitle={'Custom Disclaimer Text'}
              textAreaValueField={'agentGuidedTourDisclaimerText'}
              textAreaValue={this.state.agentGuidedTourDisclaimerText}
              toolTipText={
                'This text will be displayed on the Doorway Scheduler dialogue above the current disclaimer text. It will be displayed when the user selects the Agent Guided Tour radio button. Not Required.'
              }
              inputChanged={this.inputChanged}
            />
            <InputURL
              inputURLTitle={'Disclaimer URL'}
              inputURLValue={this.state.agentGuidedTourDisclaimerURL}
              URLChanged={this._URLChanged}
              inputURLField={'agentGuidedTourDisclaimerURL'}
              textAreaValue={this.state.agentGuidedTourDisclaimerText}
              errors={this.state.errors}
              toolTipText={
                'This URL will be appended to the end of the Custom disclaimer text. Not required.'
              }
            />
          </div>
        </div>

        <div className="property-info-edit-custom-item">
          <div>Self-Guided Tour</div>
          <div>
            <InputCustomText
              textAreaTitle={'Custom Disclaimer Text'}
              textAreaValueField={'selfGuidedTourDisclaimerText'}
              textAreaValue={this.state.selfGuidedTourDisclaimerText}
              toolTipText={
                'This text will be displayed on the Doorway Scheduler dialogue above the current disclaimer text. It will be displayed when the user selects the Self-Guided Tour radio button. Not Required.'
              }
              inputChanged={this.inputChanged}
            />
            <InputURL
              inputURLTitle={'Disclaimer URL'}
              inputURLValue={this.state.selfGuidedTourDisclaimerURL}
              URLChanged={this._URLChanged}
              inputURLField={'selfGuidedTourDisclaimerURL'}
              textAreaValue={this.state.selfGuidedTourDisclaimerText}
              errors={this.state.errors}
              toolTipText={
                'This URL will be appended to the end of the Custom disclaimer text. Not required.'
              }
            />
          </div>
        </div>

        <div className="property-info-edit-custom-item">
          <div>Live Video Tour</div>
          <div>
            <InputCustomText
              textAreaTitle={'Custom Disclaimer Text'}
              textAreaValueField={'liveVideoTourDisclaimerText'}
              textAreaValue={this.state.liveVideoTourDisclaimerText}
              toolTipText={
                'This text will be displayed on the Doorway Scheduler dialogue above the current disclaimer text. It will be displayed when the user selects the Live Video Tour radio button. Not Required.'
              }
              inputChanged={this.inputChanged}
            />
            <InputURL
              inputURLTitle={'Disclaimer URL'}
              inputURLValue={this.state.liveVideoTourDisclaimerURL}
              URLChanged={this._URLChanged}
              inputURLField={'liveVideoTourDisclaimerURL'}
              textAreaValue={this.state.liveVideoTourDisclaimerText}
              errors={this.state.errors}
              toolTipText={
                'This URL will be appended to the end of the Custom disclaimer text. Not required.'
              }
            />
          </div>
        </div>
      </div>
    );
  };

  renderIncludeInKnockbotSection = () => {
    const showCustomQuestionButton = this.state.customDetails.length <= 4;
    const showSaveButton =
      this.state.customDetails.length > 0 && this.state.editedIndex !== null;
    const showUnsavedChangesNotification = this.state.isDirty;

    const {
      property: {
        data: {
          leasing: { application: { link: applicationUrl } = {} } = {},
        } = {},
      },
    } = this.props;

    const applyNowDisabledTooltipText =
      'This button is currently disabled because the Application URL supplied is not valid.';
    let applyNowToolTipText =
      'This button will bring users to your Application portal suppled in the Admin > Properties > Application Information > Application URL field.';
    if (!applicationUrl) {
      applyNowToolTipText += ' ' + applyNowDisabledTooltipText;
    }

    let somethingElseToolTipText = '';
    if (this.props.isChatEnabled) {
      somethingElseToolTipText =
        'This functionality is disabled when AIChat is turned on. If you have questions please work with your Account Manager';
    }

    return (
      <div className="property-info-edit-custom-include-container">
        <PropertyInfoFaqCheckboxInput
          label="Prices/Availability"
          checked={this.state.availabilityIsActive}
          onChange={this.includeInKnockbotMenuInputChanged}
          name={'availabilityIsActive'}
        />
        <PropertyInfoFaqCheckboxInput
          label="View Photos"
          checked={this.state.galleryIsActive}
          onChange={this.includeInKnockbotMenuInputChanged}
          name={'galleryIsActive'}
        />
        <PropertyInfoFaqCheckboxInput
          label="I'm a Resident"
          checked={this.state.renterPortalIsActive}
          onChange={this.includeInKnockbotMenuInputChanged}
          name={'renterPortalIsActive'}
        />
        <PropertyInfoFaqCheckboxInput
          label="Request A Text"
          checked={this.state.requestTextIsActive}
          onChange={this.includeInKnockbotMenuInputChanged}
          name={'requestTextIsActive'}
        />
        <PropertyInfoFaqCheckboxInput
          label="Something Else"
          checked={
            this.state.somethingElseIsActive && !this.props.isChatEnabled
          }
          onChange={this.includeInKnockbotMenuInputChanged}
          disabled={this.props.isChatEnabled}
          name={'somethingElseIsActive'}
          toolTipText={somethingElseToolTipText}
        />
        <PropertyInfoFaqCheckboxInput
          label="Leasing Specials"
          checked={this.state.leasingSpecialIsActive}
          onChange={this.includeInKnockbotMenuInputChanged}
          name={'leasingSpecialIsActive'}
        />
        <PropertyInfoFaqCheckboxInput
          label="Neighborhood"
          checked={this.state.neighborhoodIsActive}
          onChange={this.includeInKnockbotMenuInputChanged}
          name={'neighborhoodIsActive'}
        />
        <PropertyInfoFaqCheckboxInput
          label="Apply Now"
          checked={this.state.applyNowIsActive}
          onChange={this.includeInKnockbotMenuInputChanged}
          name={'applyNowIsActive'}
          disabled={!this.shouldApplyNowButtonBeEnabled(this.props.property)}
          toolTipText={applyNowToolTipText}
        />
        <PropertyInfoFaqCheckboxInput
          label="FAQs"
          checked={this.state.faqsIsActive}
          onChange={this.includeInKnockbotMenuInputChanged}
          name={'faqsIsActive'}
        />

        {this.state.faqsIsActive && (
          <div className="property-info-edit-custom-faq-container">
            <h4>Frequently Asked Questions</h4>

            <PropertyInfoFaqCheckboxInput
              label="What duration of lease length is required?"
              checked={this.state.leasingInfoIsActive}
              onChange={this.inputChanged('leasingInfoIsActive', true)}
            />
            <PropertyInfoFaqCheckboxInput
              label="Are pets allowed? What about cats or dogs?"
              checked={this.state.petInfoIsActive}
              onChange={this.inputChanged('petInfoIsActive', true)}
            />

            <form
              ref="form"
              onSubmit={this.submit.bind(this)}
              autoComplete="off"
            >
              <h4>Custom Questions</h4>
              {this.state.customDetails.map((customDetail, key) => {
                return this.renderCustomQuestion(customDetail, key);
              })}

              <div className="form-buttons">
                {showCustomQuestionButton && (
                  <button
                    className="knock-react-button btn-info"
                    disabled={this.state.isDirty}
                    onClick={this.generateNewCustomDetail.bind(this)}
                  >
                    <i className="fa fa-lg fa-plus-circle mr-2" />
                    Custom Question
                  </button>
                )}
                {showSaveButton && (
                  <button className="knock-react-button btn-success">
                    <i className="fa fa-lg fa-check-circle" />
                    Save
                  </button>
                )}
                {showUnsavedChangesNotification && (
                  <span>
                    <i className="fa fa-info-circle" />
                    There are unsaved changes!
                  </span>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      includeInQuickLaunchMenuList,
      availabilityIsActive,
      galleryIsActive,
      applyNowIsActive,
    } = this.state;
    const hasDNISwapTarget = this.state.formattedNumber.length > 0;
    return (
      <div className="property-info-edit-container">
        <h3>Doorway Settings</h3>

        <div className="property-info-edit-custom-item">
          <label>Include in Knockbot</label>
          {this.renderIncludeInKnockbotSection()}
        </div>

        <IncludeInQuickLaunchMenuSection
          includeInQuickLaunchMenuList={includeInQuickLaunchMenuList}
          availabilityIsActive={availabilityIsActive}
          galleryIsActive={galleryIsActive}
          applyNowIsActive={applyNowIsActive}
          handleIncludeInQuickLaunchChange={
            this.handleIncludeInQuickLaunchChange
          }
        />

        <div className="property-info-edit-custom-item">
          <label>Schedule a Tour Content Box</label>
          <RichTextEditor
            name="schedule_tour_content_box"
            charLimit={SCHEDULE_TOUR_CONTENT_BOX_CHAR_LIMIT}
            content={this.state.scheduleATourContentBox}
            defaultLinkTarget={LINK_TARGETS.BLANK}
            onChange={(editorData, charLength) => {
              this.setState({
                scheduleATourContentBox: editorData,
                scheduleATourContentBoxLimitExceeded:
                  charLength > SCHEDULE_TOUR_CONTENT_BOX_CHAR_LIMIT
                    ? true
                    : false,
              });
            }}
            onBlur={() => this.submitNonCustom()}
          />
          <Tooltip
            title={
              "The contents here will show up on the top of your 'Schedule a Tour' modal in Knockbot."
            }
          >
            <i className="fa-regular fa-question-circle tooltip"></i>
          </Tooltip>
        </div>

        {this.context.isDoorwayDniEnabled ? (
          <DynamicNumberInsertion
            dynamicNumberInsertionType={this.state.dynamicNumberInsertionType}
            formattedNumber={this.state.formattedNumber}
            formattedNumberIsActive={this.state.formattedNumberIsActive}
            key={this.state.dynamicNumberInsertionType}
            updateDoorwaySettings={this.updateDoorwaySettings}
          />
        ) : (
          <React.Fragment>
            <div className="property-info-edit-custom-item">
              <label>DNI Swap Target</label>
              <InputMask
                mask="(999) 999-9999"
                value={this.state.formattedNumber}
                name="formatted-number"
                onBlur={this.inputChanged('formattedNumber', true)}
                onChange={this.inputChanged('formattedNumber')}
                className="knock-react-input"
                placeholder="e.g. (206) 555-1234"
              />
              <Tooltip title="This number is what our DNI logic will look for to replace with the correct tracking number">
                <i className="fa fa-lg fa-question-circle-o tooltip"></i>
              </Tooltip>
            </div>
            <PropertyInfoCheckboxInput
              title="This feature requires a DNI Swap Target"
              isDisabled={!hasDNISwapTarget}
              label="Dynamic number insertion"
              checked={hasDNISwapTarget && this.state.formattedNumberIsActive}
              onChange={this.inputChanged('formattedNumberIsActive', true)}
            />
          </React.Fragment>
        )}

        <PropertyInfoCheckboxInput
          title="If checked, will hide all Knock specific branding from the doorway widget"
          label="Hide Knock Branding"
          checked={this.state.hideKnockBranding}
          onChange={this.inputChanged('hideKnockBranding', true)}
        />

        <PropertyInfoCheckboxInput
          label="Hide bedroom dropdown in Scheduling Form"
          checked={this.state.hideUnitPreferences}
          onChange={this.inputChanged('hideUnitPreferences', true)}
        />

        <PropertyInfoCheckboxInput
          label="Hide unit dropdown in Scheduling Form"
          checked={this.state.hideUnitSelector}
          onChange={this.inputChanged('hideUnitSelector', true)}
        />
        {this.context.isDoorwayPricingComplianceEnabled && (
          <PropertyInfoCheckboxInput
            label="Hide Pricing"
            checked={this.state.hidePricing}
            onChange={this.inputChanged('hidePricing', true)}
          />
        )}
        <PropertyInfoCheckboxInput
          label="Limit Number of Available Units Displayed"
          checked={this.state.limitAvailableUnitsShown}
          onChange={this.inputChanged('limitAvailableUnitsShown', true)}
        />
        <PropertyInfoCheckboxInput
          label="Allow custom message on Scheduling and Contact Forms"
          checked={this.state.showNoteInput}
          onChange={this.inputChanged('showNoteInput', true)}
        />
        <PropertyInfoCheckboxInput
          label="Redirect prospects to property website"
          checked={this.state.useCustomWebsite}
          onChange={this.inputChanged('useCustomWebsite', true)}
        />
        <PropertyInfoCheckboxInput
          label="Generate Bedroom options based on floorplans"
          title="If enabled, allows Doorway to show only the bedroom options a property has in inventory, regardless of availability. If disabled, will show all bedroom options."
          checked={this.state.useDynamicFloorplans}
          onChange={this.inputChanged('useDynamicFloorplans', true)}
        />
        <div className="property-info-edit-custom-item">
          <label>Resident Portal URL</label>
          <input
            name="residentPortalURL"
            value={this.state.residentPortalURL}
            title="Enter the link to your resident portal here and we will share this with confirmed residents."
            onChange={this._URLChanged()}
            placeholder="http(s)://www..."
            onBlur={this._URLChanged(true)}
            className="knock-react-input"
          />
          <Tooltip title="Enter the link to your resident portal here and we will share this with confirmed residents.">
            <i className="fa-regular fa-question-circle tooltip"></i>
          </Tooltip>
          {this.state.errors.residentPortalURL && (
            <span className="knock-react-form-hint knock-react-form-hint-error">
              {this.state.errors.residentPortalURL}
            </span>
          )}
        </div>
        <div className="property-info-edit-custom-item">
          <label>Banner Text</label>
          <textarea
            value={this.state.bannerText}
            onChange={this.inputChanged('bannerText')}
            onBlur={this.inputChanged('bannerText', true)}
          />
        </div>

        <div className="property-info-edit-custom-item">
          <label>Banner URL</label>
          <input
            name="bannerURL"
            value={this.state.bannerURL}
            onChange={this._URLChanged()}
            placeholder="http(s)://www..."
            onBlur={this._URLChanged(true)}
            disabled={!this.state.bannerText}
            className="knock-react-input"
            data-testid="input-banner-url"
          />

          {!this.state.bannerText && (
            <span className="helper-text">
              Please fill in the Banner Text field first.
            </span>
          )}

          {this.state.errors.bannerURL && (
            <span className="knock-react-form-hint knock-react-form-hint-error">
              {this.state.errors.bannerURL}
            </span>
          )}
        </div>

        <div className="property-info-edit-custom-item">
          <label>Custom Appointment Message</label>
          <textarea
            value={this.state.customAppointmentMessage}
            onChange={this.inputChanged('customAppointmentMessage')}
            onBlur={this.inputChanged('customAppointmentMessage', true)}
            maxLength={500}
          />
        </div>

        <PropertyInfoCheckboxInput
          label="Exclude phone number from appointment message"
          checked={this.state.excludeAppointmentMessagePhone}
          onChange={this.inputChanged('excludeAppointmentMessagePhone', true)}
        />

        {this.renderSelfGuidedSection()}
      </div>
    );
  }

  includeInKnockbotMenuInputChanged = (event) => {
    const mapToAssociatedIncludeInQuickLaunchField = {
      availabilityIsActive: 'availability',
      galleryIsActive: 'gallery',
    };

    if (event.target.validity.valid) {
      const targetValue = event.target.checked;
      const targetName = event.target.name;

      const includeInQuickLaunchMenuList = [
        ...this.state.includeInQuickLaunchMenuList,
      ];

      if (targetName in mapToAssociatedIncludeInQuickLaunchField) {
        const el = includeInQuickLaunchMenuList.find(
          (item) =>
            item.name === mapToAssociatedIncludeInQuickLaunchField[targetName]
        );
        el.enabled = targetValue;
      }

      this.setState({
        [targetName]: targetValue,
        includeInQuickLaunchMenuList,
      });

      this.submitNonCustom();
    }
  };

  handleIncludeInQuickLaunchChange = (newItems) => {
    this.setState({
      includeInQuickLaunchMenuList: newItems,
    });
    this.submitNonCustom();
  };

  updateDoorwaySettings = (updates) => {
    this.setState(updates, this.submitNonCustom);
  };

  updateField = (fieldUpdate, isSubmit) => {
    this.setState(fieldUpdate);

    if (isSubmit) {
      this.submitNonCustom();
    }
  };

  inputChanged = (field, isSubmit) => (event) => {
    if (event.target.validity.valid) {
      const targetValue =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value;

      const fieldUpdate = { [field]: targetValue };
      this.updateField(fieldUpdate, isSubmit);
    }
  };

  submitNonCustom() {
    if (this.state.scheduleATourContentBoxLimitExceeded) {
      Toaster.showToast(
        '"Schedule a Tour Content Box" character limit exceeded.',
        2000,
        Toaster.ToastClasses.error
      );
      return;
    }
    setTimeout(() => {
      const errors = this.state.errors || {};
      const updatedCommunity = this.props.property.data;

      updatedCommunity.doorway.availabilityIsActive =
        this.state.availabilityIsActive;
      updatedCommunity.doorway.galleryIsActive = this.state.galleryIsActive;
      updatedCommunity.doorway.somethingElseIsActive =
        this.state.somethingElseIsActive;
      updatedCommunity.doorway.renterPortalIsActive =
        this.state.renterPortalIsActive;
      updatedCommunity.doorway.requestTextIsActive =
        this.state.requestTextIsActive;
      updatedCommunity.doorway.leasingSpecialIsActive =
        this.state.leasingSpecialIsActive;
      updatedCommunity.doorway.faqsIsActive = this.state.faqsIsActive;
      updatedCommunity.doorway.neighborhoodIsActive =
        this.state.neighborhoodIsActive;
      updatedCommunity.doorway.applyNowIsActive = this.state.applyNowIsActive;
      updatedCommunity.doorway.dynamicNumberInsertionType =
        this.state.dynamicNumberInsertionType;
      updatedCommunity.doorway.formattedNumber = this.state.formattedNumber;
      updatedCommunity.doorway.formattedNumberIsActive =
        this.state.formattedNumberIsActive;
      updatedCommunity.doorway.hideKnockBranding = this.state.hideKnockBranding;

      if (!errors.residentPortalURL) {
        updatedCommunity.doorway.residentPortalURL =
          this.state.residentPortalURL;
      }

      updatedCommunity.doorway.useDynamicFloorplans =
        this.state.useDynamicFloorplans;
      updatedCommunity.doorway.hideUnitPreferences =
        this.state.hideUnitPreferences;
      updatedCommunity.doorway.hideUnitSelector = this.state.hideUnitSelector;
      updatedCommunity.doorway.hidePricing = this.state.hidePricing;
      updatedCommunity.doorway.limitAvailableUnitsShown =
        this.state.limitAvailableUnitsShown;
      updatedCommunity.doorway.showNoteInput = this.state.showNoteInput;
      updatedCommunity.doorway.leasingInfoIsActive =
        this.state.leasingInfoIsActive;
      updatedCommunity.doorway.petInfoIsActive = this.state.petInfoIsActive;
      updatedCommunity.doorway.useCustomWebsite = this.state.useCustomWebsite;
      updatedCommunity.doorway.bannerText = this.state.bannerText;
      updatedCommunity.doorway.liveVideoTourDisclaimerText =
        this.state.liveVideoTourDisclaimerText;
      updatedCommunity.doorway.agentGuidedTourDisclaimerText =
        this.state.agentGuidedTourDisclaimerText;
      updatedCommunity.doorway.includeInQuickLaunchMenuList =
        this.state.includeInQuickLaunchMenuList;

      updatedCommunity.doorway.selfGuidedTourDisclaimerText =
        this.state.selfGuidedTourDisclaimerText;

      if (!errors.bannerURL && updatedCommunity.doorway.bannerText) {
        updatedCommunity.doorway.bannerURL = this.state.bannerURL;
      }

      if (
        !errors.liveVideoTourDisclaimerURL &&
        updatedCommunity.doorway.liveVideoTourDisclaimerText
      ) {
        updatedCommunity.doorway.liveVideoTourDisclaimerURL =
          this.state.liveVideoTourDisclaimerURL;
      }

      if (
        !errors.agentGuidedTourDisclaimerURL &&
        updatedCommunity.doorway.agentGuidedTourDisclaimerText
      ) {
        updatedCommunity.doorway.agentGuidedTourDisclaimerURL =
          this.state.agentGuidedTourDisclaimerURL;
      }

      if (
        !errors.selfGuidedTourDisclaimerURL &&
        updatedCommunity.doorway.selfGuidedTourDisclaimerText
      ) {
        updatedCommunity.doorway.selfGuidedTourDisclaimerURL =
          this.state.selfGuidedTourDisclaimerURL;
      }

      updatedCommunity.doorway.excludeAppointmentMessagePhone =
        this.state.excludeAppointmentMessagePhone;
      updatedCommunity.doorway.customAppointmentMessage = trim(
        this.state.customAppointmentMessage
      );
      updatedCommunity.doorway.scheduleATourContentBox =
        this.state.scheduleATourContentBox;

      const updatedProperty = this.props.property;
      updatedProperty.data = updatedCommunity;

      this.props.onChange(updatedProperty);
    }, 100);
  }

  customDetailChanged(index, attribute) {
    return (event) => {
      const targetValue =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value;

      const customDetails = this.state.customDetails;
      customDetails[index][attribute] = targetValue;

      // this is used to bypass checkboxes triggering incorrect state
      const isADirtyLittleTextBox =
        event.target.type === 'checkbox' && !this.editedIndex
          ? false
          : this.state.isDirty;

      this.setState(
        {
          isDirty: isADirtyLittleTextBox,
          customDetails: customDetails,
        },
        () => {
          if (this.state.editedIndex === null) {
            this._executeChange();
          }
        }
      );
    };
  }

  generateNewCustomDetail() {
    const customDetails = this.state.customDetails;
    customDetails.push({
      title: '',
      details: '',
      isActive: false,
    });

    this.setState({
      isDirty: true,
      customDetails,
      editedIndex: customDetails.length - 1,
    });
  }

  editCustomDetail(index) {
    if (this.state.editedIndex === index) {
      this.setState({ editedIndex: null });
      return;
    } else {
      this.setState({
        editedIndex: index,
      });
    }
  }

  removeCustomDetail(index) {
    const customDetails = this.state.customDetails;
    customDetails.splice(index, 1);
    this.setState({ customDetails }, () => this.submit());
  }

  submit(event) {
    if (event) {
      event.preventDefault();
    }
    this._executeChange();
  }

  _executeChange() {
    const updatedCommunity = this.props.property.data;
    updatedCommunity.customDetails = this.state.customDetails;

    const updatedProperty = this.props.property;
    updatedProperty.data = updatedCommunity;

    this.props.onChange(updatedProperty);

    this.setState({
      isDirty: false,
    });
  }

  _validateURL = (url, field) => {
    const errorMessage = url && !validateUrl(url) ? INVALID_URL_MESSAGE : '';

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));

    return !errorMessage;
  };

  _URLChanged = (isSubmit) => (event) => {
    const {
      target: { name, value: url },
    } = event;
    const isValid = this._validateURL(url, name);

    const fieldUpdate = { [name]: url };
    this.updateField(fieldUpdate, isValid && isSubmit);
  };
}

PropertyInfoEditCustom.propTypes = {
  property: PropTypes.object.isRequired,
  isChatEnabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PropertyInfoEditCustom;

PropertyInfoEditCustom.contextType = FeatureFlagContext;
