import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { CommunityLogo } from '../../../Components/CommunityLogo';

export const PropertyLogo = ({ logos, updatePropertyLogo }) => {
  const logoUrl = logos.length ? logos[0].url : '';

  const onLogoRemove = () => {
    updatePropertyLogo([]);
  };

  const onLogoUpload = ({ thumbUrl, url }) => {
    const logos = [
      {
        thumbUrl,
        url,
      },
    ];
    updatePropertyLogo(logos);
  };

  return (
    <div className="full-width property-info-edit-container">
      <h1>Logo</h1>
      <div className="property-info-edit-field-container">
        <ThemeProvider>
          <CommunityLogo
            logoUrl={logoUrl}
            onLogoRemove={onLogoRemove}
            onLogoUpload={onLogoUpload}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

PropertyLogo.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      thumbUrl: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  updatePropertyLogo: PropTypes.func,
};
