export const PREFERENCE_NAMES = {
  REQUIRE_LEASE_TERM: 'require_lease_term',
  REQUIRE_PREFERRED_BEDROOM_COUNT: 'require_preferred_bedroom_count',
  REQUIRE_PREFERRED_UNIT: 'require_preferred_unit',
  REQUIRE_PROSPECT_FLOOR_PLAN: 'require_prospect_floorplan',
  REQUIRE_PROSPECT_MOVE_IN_DATE: 'require_prospect_move_in_date',
  SELF_GUIDED_TOUR_BUTTON_LABEL: 'self_guided_tour_button_label',
  SELF_GUIDED_TOUR_URL: 'self_guided_tour_url',
  TOURS_EXPORT_ONLY_FAVORITE_UNIT: 'tours_export_only_favorite_unit',
};

export const IS_CHILD_PREFERENCE = {
  [PREFERENCE_NAMES.SELF_GUIDED_TOUR_BUTTON_LABEL]: true,
  [PREFERENCE_NAMES.SELF_GUIDED_TOUR_URL]: true,
  [PREFERENCE_NAMES.TOURS_EXPORT_ONLY_FAVORITE_UNIT]: true,
};

export const LABELS = {
  [PREFERENCE_NAMES.REQUIRE_PROSPECT_FLOOR_PLAN]: 'Require Prospect Floor Plan',
  [PREFERENCE_NAMES.REQUIRE_PROSPECT_MOVE_IN_DATE]:
    'Require Prospect Move-in Date',
  [PREFERENCE_NAMES.REQUIRE_LEASE_TERM]: 'Require Lease Term',
  [PREFERENCE_NAMES.REQUIRE_PREFERRED_UNIT]: 'Require Preferred Unit',
  [PREFERENCE_NAMES.REQUIRE_PREFERRED_BEDROOM_COUNT]:
    'Require Preferred Bedroom Count',
  [PREFERENCE_NAMES.TOURS_EXPORT_ONLY_FAVORITE_UNIT]:
    'Tours Export Only Favorite Unit',
  [PREFERENCE_NAMES.SELF_GUIDED_TOUR_URL]: 'Self-Guided Tour URL',
  [PREFERENCE_NAMES.SELF_GUIDED_TOUR_BUTTON_LABEL]:
    'Self-Guided Tour Button Label',
};

export const PLACEHOLDER_TEXT = {
  [PREFERENCE_NAMES.SELF_GUIDED_TOUR_URL]: 'Self-Guided Tour URL',
  [PREFERENCE_NAMES.SELF_GUIDED_TOUR_BUTTON_LABEL]: 'Custom Label Text',
};
