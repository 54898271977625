import React, { Component } from 'react';
import {
  PageContainer,
  Toaster,
  Alert,
  AuthenticationService,
} from '@knockrentals/knock-react';
import UsersAPI from './UsersAPI';
import GroupUsers from './GroupUsers';
import TeamEditor from './TeamEditor';
import TeamImport from './TeamImport';
import UserImport from './UserImport';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import { sortCaseInsensitive } from '../../Utils';
import { ADMIN_USER_ROLES } from '../../constants';
import './_usersPage.scss';

const SELECT_GROUP_ID_ALL = 'all';

class UsersPage extends Component {
  state = {
    isLoading: true,
    groups: [],
    selectedGroupId: '',
    isAddingLeasingTeam: false,
    isImportingLeasingTeams: false,
    isImportingUsers: false,
    isUserManagementEnabled:
      AuthenticationService.getRole() === ADMIN_USER_ROLES.MASTER || null,
    userImportErrors: null,
    teamImportErrors: null,
  };

  static contextType = FeatureFlagContext;

  async componentDidMount() {
    this.load();
  }

  userDeleteDisabled = () => {
    const isInternalMode = AuthenticationService._internalMode;
    return !isInternalMode && this.context.isUserDeleteDisabled;
  };

  getIsUserManagementEnabled = async () => {
    const response = await UsersAPI.getCurrentUser();
    return response?.user?.user_creation_enabled || false;
  };

  setIsUserManagementEnabled = async () => {
    const isUserManagementEnabled = await this.getIsUserManagementEnabled();
    this.setState({ isUserManagementEnabled });
  };

  async load(showLoading = true) {
    this.setState({ isLoading: showLoading });

    const response = await UsersAPI.getCompanyUsers();
    const groups = response.leasing_team_users.sort(
      sortCaseInsensitive('leasing_team_name')
    );

    const deletionsInProgress = this.syncWithPrevDeletionProgress(groups);

    const stateUpdates = {
      deletionsInProgress,
      groups,
      isLoading: false,
    };

    if (!this.state.selectedGroupId && response.leasing_team_users.length > 0) {
      stateUpdates.selectedGroupId =
        response.leasing_team_users[0].leasing_team_id;
    }

    this.setState(stateUpdates);
  }

  renderImportButtons() {
    if (!AuthenticationService._internalMode) {
      return null;
    }

    return (
      <div>
        <button
          className="knock-react-button"
          onClick={this.importLeasingTeams.bind(this)}
        >
          Import leasing teams
        </button>

        <button
          className="knock-react-button"
          onClick={this.importUsers.bind(this)}
        >
          Import users
        </button>
      </div>
    );
  }

  render() {
    return (
      <PageContainer
        className="users-page-container"
        isLoading={this.state.isLoading}
      >
        <h1>Users</h1>

        {this.userDeleteDisabled() && (
          <h4>
            We have temporarily disabled the Delete User button. Should you need
            assistance please reach out to your CSM.
          </h4>
        )}

        <div className="knock-react-flex knock-react-flex-row">
          <div>
            <div>Leasing team</div>
            <select
              value={this.state.selectedGroupId}
              onChange={this.onGroupChanged.bind(this)}
            >
              <option value={SELECT_GROUP_ID_ALL}>All</option>
              {this.renderGroupOptions()}
            </select>
          </div>

          {AuthenticationService._internalMode && (
            <div>
              <button
                className="knock-react-button"
                onClick={this.addLeasingTeam.bind(this)}
              >
                Add leasing team
              </button>
            </div>
          )}
          {this.renderImportButtons()}
        </div>

        {this.state.isAddingLeasingTeam ? (
          <TeamEditor
            onCancel={this.cancelAddLeasingTeam.bind(this)}
            onAddTeam={this.onAddTeam.bind(this)}
          />
        ) : null}

        {this.state.isImportingLeasingTeams ? (
          <TeamImport
            onCancel={this.cancelImportingLeasingTeams.bind(this)}
            onImportTeams={this.onImportTeams.bind(this)}
            importErrors={this.state.teamImportErrors}
          />
        ) : null}

        {this.state.isImportingUsers ? (
          <UserImport
            onCancel={this.cancelImportingUsers.bind(this)}
            onImportUsers={this.onImportUsers.bind(this)}
            importErrors={this.state.userImportErrors}
          />
        ) : null}
        {this.state.selectedGroupId && this.renderGroups()}
      </PageContainer>
    );
  }

  onGroupChanged(event) {
    this.setState({
      selectedGroupId:
        event.target.value === SELECT_GROUP_ID_ALL
          ? event.target.value
          : parseInt(event.target.value, 10),
    });
  }

  addLeasingTeam() {
    this.setState({ isAddingLeasingTeam: true });
  }

  importLeasingTeams() {
    this.setState({ isImportingLeasingTeams: true });
  }

  importUsers() {
    this.setState({ isImportingUsers: true });
  }

  cancelAddLeasingTeam() {
    this.setState({ isAddingLeasingTeam: false });
  }

  cancelImportingLeasingTeams() {
    this.setState({ isImportingLeasingTeams: false });
  }

  cancelImportingUsers() {
    this.setState({ isImportingUsers: false });
  }

  onUpdateTeam(groupId, teamName) {
    this.setState({ isAddingLeasingTeam: false });

    UsersAPI.updateGroup(groupId, teamName)
      .then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        const updatedGroups = this.state.groups.slice();

        updatedGroups.find(
          (group) => group.leasing_team_id === groupId
        ).leasing_team_name = teamName;

        this.setState({ groups: updatedGroups });
      })
      .catch(() => {
        Toaster.showToast(
          'Error updating team',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  onAddTeam(teamName) {
    this.setState({ isAddingLeasingTeam: false });

    UsersAPI.addGroup(teamName)
      .then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        this.load();
      })
      .catch(() => {
        Toaster.showToast(
          'Error adding team',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  onImportTeams(teamsFile) {
    const data = new FormData();
    data.append('leasing-teams-import', teamsFile, 'leasing-teams-import.csv');

    return UsersAPI.importTeams(data)
      .then((response) => {
        if (response.is_invalid) {
          const teamImportErrors = {
            invalidMasterUsernames: response.invalid_master_usernames,
          };

          this.setState({ teamImportErrors });

          Toaster.showToast(
            'Error importing users',
            2000,
            Toaster.ToastClasses.error
          );
        } else {
          Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);

          this.setState({
            isImportingLeasingTeams: false,
            teamImportErrors: null,
          });
          this.load();
        }
      })
      .catch(() => {
        this.setState({ teamImportErrors: null });
        Toaster.showToast(
          'Error importing teams',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  onImportUsers(usersFile) {
    const data = new FormData();
    data.append('users-import', usersFile, 'users-import.csv');

    return UsersAPI.importUsers(data)
      .then((response) => {
        if (response.is_invalid) {
          let userImportErrors = {};
          if (response.failed_user_records) {
            userImportErrors = {
              failedUserRecords: response.failed_user_records,
              importedUserRecords: response.imported_user_records,
            };
          } else {
            userImportErrors = {
              invalidPublicIds: response.invalid_public_ids,
              duplicateManagersUsernames: response.duplicate_managers_usernames,
              existingManagersUsernames: response.existing_managers_usernames,
              invalidTimezones: response.invalid_timezones,
            };
          }
          this.setState({ userImportErrors });
          Toaster.showToast(
            'Error importing users',
            2000,
            Toaster.ToastClasses.error
          );
        } else {
          Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);

          this.setState({ isImportingUsers: false, userImportErrors: null });
          this.load();
        }
      })
      .catch(() => {
        this.setState({ userImportErrors: null });
        Toaster.showToast(
          'Error importing users',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  renderGroupOptions() {
    return this.state.groups.map((group) => {
      return (
        <option key={group.leasing_team_id} value={group.leasing_team_id}>
          {group.leasing_team_name}
        </option>
      );
    });
  }

  renderGroups() {
    if (!this.state.groups) {
      return <Alert>No leasing teams to display.</Alert>;
    }

    if (this.state.selectedGroupId === SELECT_GROUP_ID_ALL) {
      return this.state.groups.map((group) => {
        return (
          <GroupUsers
            key={group.leasing_team_id}
            group={group}
            groups={this.state.groups}
            isUserManagementEnabled={this.state.isUserManagementEnabled}
            onUpdateTeam={this.onUpdateTeam.bind(this)}
            onUserUpdated={this.onUserUpdated.bind(this)}
            onUserCreated={this.onUserCreated.bind(this)}
            onUserDeleted={this.onUserDeleted.bind(this)}
            onUserPasswordUpdated={this.onUserPasswordUpdated.bind(this)}
            setIsUserManagementEnabled={this.setIsUserManagementEnabled}
          />
        );
      });
    }

    const selectedGroup = this.state.groups.find(
      (group) => group.leasing_team_id === this.state.selectedGroupId
    );

    return (
      <GroupUsers
        group={selectedGroup}
        groups={this.state.groups}
        isUserManagementEnabled={this.state.isUserManagementEnabled}
        onUpdateTeam={this.onUpdateTeam.bind(this)}
        onUserUpdated={this.onUserUpdated.bind(this)}
        onUserCreated={this.onUserCreated.bind(this)}
        onUserDeleted={this.onUserDeleted.bind(this)}
        onUserPasswordUpdated={this.onUserPasswordUpdated.bind(this)}
        setIsUserManagementEnabled={this.setIsUserManagementEnabled}
      />
    );
  }

  onUserUpdated(updatedUser) {
    return UsersAPI.updateGroupUser(
      updatedUser.user_id,
      updatedUser,
      updatedUser.integrations
    )
      .then(async (result) => {
        const response = await result.json();
        if (response.message === 'CONFLICT') {
          Toaster.showToast(
            'Username taken!',
            2000,
            Toaster.ToastClasses.error
          );
          return false;
        } else if (response.status_code === 'VALIDATION_ERROR') {
          Toaster.showToast(response.message, 4000, Toaster.ToastClasses.error);
          return false;
        }
        if (result.status < 200 || result.status >= 400) {
          throw new Error();
        }
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        this.load(false);
        return true;
      })
      .catch(() => {
        Toaster.showToast(
          'Error updating user',
          2000,
          Toaster.ToastClasses.error
        );
        return false;
      });
  }

  onUserCreated(user) {
    return UsersAPI.createGroupUser(user)
      .then(async (result) => {
        const response = await result.json();
        if (response.message === 'CONFLICT') {
          Toaster.showToast(
            'Username taken!',
            2000,
            Toaster.ToastClasses.error
          );
          return false;
        } else if (response.status_code === 'VALIDATION_ERROR') {
          Toaster.showToast(response.message, 4000, Toaster.ToastClasses.error);
          return false;
        }
        if (result.status < 200 || result.status >= 400) {
          throw new Error();
        }
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        this.load(false);
        return true;
      })
      .catch(() => {
        Toaster.showToast(
          'Error adding user',
          2000,
          Toaster.ToastClasses.error
        );
        return false;
      });
  }

  onUserPasswordUpdated(userId, password) {
    if (password && password.length < 8) {
      Toaster.showToast(
        'Password must be at least 8 characters.',
        2000,
        Toaster.ToastClasses.error
      );
      return;
    }

    return UsersAPI.updateUserPassword(userId, password)
      .then(async (response) => {
        if (response.status === 400) {
          const json = await response.json();
          Toaster.showToast(json.message, 2000, Toaster.ToastClasses.error);

          return;
        }

        this.load(false);
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      })
      .catch(() => {
        Toaster.showToast(
          'Error updating password',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  onUserDeleted(userId, transferToId) {
    let deletionsInProgress = [];
    if (sessionStorage['deletionsInProgress']) {
      deletionsInProgress = JSON.parse(
        sessionStorage.getItem('deletionsInProgress')
      );
    }

    deletionsInProgress.push(userId);
    sessionStorage.setItem(
      'deletionsInProgress',
      JSON.stringify(deletionsInProgress)
    );

    return UsersAPI.deleteUser(userId, transferToId)
      .then(() => {
        Toaster.showToast(
          'User deletion queued! Refresh page in 5 minutes.',
          3000,
          Toaster.ToastClasses.success
        );
      })
      .catch(() => {
        Toaster.showToast(
          'Error deleting user',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  syncWithPrevDeletionProgress(groups) {
    const prevProgress = JSON.parse(
      sessionStorage.getItem('deletionsInProgress')
    );

    const deletionsInProgress = [];
    const leasingTeamUsers = groups.map((group) => group.users);

    if (prevProgress !== null && prevProgress.length > 0) {
      prevProgress.forEach((id) => {
        if (leasingTeamUsers.includes(id)) {
          deletionsInProgress.push(id);
        }
        return false;
      });
    }

    sessionStorage.setItem(
      'deletionsInProgress',
      JSON.stringify(deletionsInProgress)
    );
    return deletionsInProgress;
  }
}

export default UsersPage;
