import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DragSortableList from 'react-drag-sortable';
import { FileDrop } from 'react-file-drop';
import PhotosAPI from '../../Photos/PhotosAPI';
import PhotoDescription from './PhotoDescription';

class PropertyPhotos extends Component {
  MAX_FILE_COUNT = 24;
  MAX_FILE_SIZE_MB = 10;
  UPLOAD_KEY_COMMUNITY = 'community';

  _fileInput = null;
  _dropBoxDiv = null;

  state = {
    inputKey: new Date(),
    photoStates: [],
  };

  componentDidMount() {
    if (this.props.property.data.photos) {
      this.setState({
        photoStates: this.props.property.data.photos.map((photo) => {
          return {
            payload: photo,
            localFile: null,
            progress: null,
            isError: false,
            isSaved: true,
          };
        }),
      });
    }
  }

  render() {
    const savedPhotoStateComponents = this.state.photoStates.reduce(
      (savedPhotos, photoState, key) => {
        const savedPhotoStateComponent = this.renderPhotoSingleSaved(
          key,
          photoState
        );
        if (savedPhotoStateComponent) {
          savedPhotos.push(savedPhotoStateComponent);
        }
        return savedPhotos;
      },
      []
    );

    return (
      <div className="property-photos">
        <div className="pull-right">
          {this.state.photoStates.length < this.MAX_FILE_COUNT && (
            <input
              aria-label="image upload"
              key={this.state.inputKey}
              ref={(input) => {
                this._fileInput = input;
              }}
              className="image-file-input"
              type="file"
              multiple
              accept="image/*"
              onChange={this.handleFilesFromInputChange.bind(this)}
            />
          )}
        </div>

        <h2>{this.props.property.property_name}</h2>

        <div aria-label="photos" className="property-photos-container">
          <DragSortableList
            items={savedPhotoStateComponents}
            onSort={this.handleSort.bind(this)}
            type="grid"
          />

          {this.state.photoStates.map((photoState, key) => {
            return this.renderPhotoSingleLocal(key, photoState);
          })}

          {this.renderPhotoFileDrop()}
        </div>
      </div>
    );
  }

  renderPhotoSingleSaved(key, photoState) {
    let draggableObjectContent = null;
    if (photoState.isSaved && photoState.payload) {
      draggableObjectContent = (
        <div>
          <div
            aria-label="photo"
            key={`saved-photos-${key}`}
            className="property-photos-container-single drag-hand"
            style={{ backgroundImage: `url(${photoState.payload.thumbUrl})` }}
          >
            {this.renderPhotoStatusInformation(key, photoState)}
          </div>

          <PhotoDescription
            description={photoState.payload.description}
            updateDescription={this.updatePhotDescription(key)}
          />
        </div>
      );
    }

    return (
      draggableObjectContent && {
        photoState: photoState,
        content: draggableObjectContent,
        classes: ['property-photos-container-single-wrap'],
      }
    );
  }

  openPhotoPreview(photoState) {
    if (photoState.payload) {
      this.props.setImageToPreview(photoState.payload.url);
    }
  }

  renderPhotoSingleLocal(key, photoState) {
    let content = null;
    if (photoState.localFile) {
      this.readPhotoStateFileAsDataUrl(key);
      content = (
        <div
          key={`local-photos-${key}`}
          className="property-photos-container-single-wrap"
        >
          <div
            aria-label="pending photo"
            className="property-photos-container-single loading"
            style={{ backgroundImage: `url(${photoState.localFileAsData})` }}
          >
            {this.renderPhotoStatusInformation(key, photoState)}
          </div>
        </div>
      );
    }
    return content;
  }

  renderPhotoFileDrop() {
    let component = null;
    if (this.state.photoStates.length < this.MAX_FILE_COUNT) {
      component = (
        <div
          key={`photos-file-drop`}
          ref={(div) => {
            this._dropBoxDiv = div;
          }}
          className="property-photos-container-single-wrap"
        >
          <div
            className="property-photos-container-single property-photos-container-single-file-drop"
            onClick={this.openFileInputDialog.bind(this)}
          >
            <div className="property-photos-container-single-file-drop-placeholder">
              Drag and drop <br />
              or click here to browse...
            </div>

            {this._dropBoxDiv && (
              <FileDrop
                frame={this._dropBoxDiv}
                targetAlwaysVisible={true}
                onDrop={this.handleFiles.bind(this)}
              ></FileDrop>
            )}
          </div>
        </div>
      );
    }
    return component;
  }

  openFileInputDialog() {
    if (this._fileInput) {
      this._fileInput.click();
    }
  }

  readPhotoStateFileAsDataUrl(key) {
    const photoStates = this.state.photoStates;
    const thisPhotoState = photoStates[key];

    if (thisPhotoState.localFileAsData) {
      return false;
    }

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        thisPhotoState.localFileAsData = reader.result;
        photoStates[key] = thisPhotoState;
        this.setState({ photoStates });
      },
      false
    );

    reader.readAsDataURL(thisPhotoState.localFile);
  }

  renderPhotoStatusInformation(key, photoState) {
    const removeButton = (
      <button
        aria-label="delete photo"
        className="pull-right btn-danger"
        onClick={this.removePhotoFromProperty.bind(this, key)}
      >
        <i className="fa fa-trash fa-lg" />
      </button>
    );

    let statusComponent;
    if (photoState.isError) {
      statusComponent = (
        <button
          aria-label="photo upload error"
          className="btn-warning"
          onClick={this.retryPhotoUpload.bind(this, key)}
        >
          <i className="fa fa-lg fa-exclamation-triangle" /> Retry
        </button>
      );
    } else if (photoState.progress !== null) {
      statusComponent = (
        <div className="btn-mock btn-primary">
          <i className="fa fa-lg fa-circle-o-notch fa-spin fa-fw" />{' '}
          {photoState.progress}%
        </div>
      );
    } else if (photoState.isSaved) {
      statusComponent = (
        <button
          aria-label="preview photo"
          className="btn-success"
          onClick={this.openPhotoPreview.bind(this, photoState)}
        >
          <i className="fa fa-lg fa-search-plus" />
        </button>
      );
    } else {
      statusComponent = null;
    }

    return (
      <div className="status-management-toolbar">
        {removeButton}
        {statusComponent}
      </div>
    );
  }

  handleFilesFromInputChange(event) {
    const targetImages = event.target.files;

    this.setState({
      inputKey: new Date(),
    });

    this.handleFiles(targetImages);
  }

  handleFiles(targetImages) {
    const totalImageCount = targetImages.length + this.state.photoStates.length;
    if (totalImageCount > this.MAX_FILE_COUNT) {
      const n = this.MAX_FILE_COUNT - this.state.photoStates.length;
      const N = targetImages.length;

      const errorMessageBody = (
        <div>
          Maximum of {this.MAX_FILE_COUNT} photos allowed for each property.
          <br />
          You currently have {this.state.photoStates.length} photos already
          uploaded.
          <br />
          You can upload the first {n} of {N} selected, or cancel the upload.
        </div>
      );

      this.props.setDialog(
        'Count of Photos Exceeded Limit',
        errorMessageBody,
        () => {
          targetImages = Array.prototype.slice.call(targetImages, 0, n);
          this.handleFilesAfterCountCheck(targetImages);
        },
        () => {
          console.log('decided to not upload... do nothing');
        }
      );
    } else {
      this.handleFilesAfterCountCheck(targetImages);
    }
  }

  handleFilesAfterCountCheck(fileList) {
    const targetImages = Array.from(fileList);
    const oversizedImages = targetImages.filter((image) => {
      return image.size / 1024 / 1024 > this.MAX_FILE_SIZE_MB;
    });

    if (oversizedImages && oversizedImages.length > 0) {
      const validImages = targetImages.filter((image) => {
        return image.size / 1024 / 1024 <= this.MAX_FILE_SIZE_MB;
      });

      const n = validImages.length;
      const N = targetImages.length;
      const errorMessageBody = (
        <div>
          Proceeding to upload {n} of {N} images that are under{' '}
          {this.MAX_FILE_SIZE_MB} Mb. <br />
          Please resize the following images to be under {
            this.MAX_FILE_SIZE_MB
          }{' '}
          Mb, then try again:
          <br />
          <ul>
            {oversizedImages.map((oversizedImage, key) => {
              return (
                <li key={`oversized-image-${key}`}>{oversizedImage.name}</li>
              );
            })}
          </ul>
        </div>
      );

      this.props.setDialog(
        'Oversized Photos',
        errorMessageBody,
        () => {
          this.handleFilesAfterSizeCheck(validImages);
        },
        null
      );
    } else {
      this.handleFilesAfterSizeCheck(targetImages);
    }
  }

  handleFilesAfterSizeCheck(targetImages) {
    targetImages.forEach((targetImage) => {
      this.startUploadByFile(targetImage);
    });
  }

  startUploadByFile(file) {
    if (!file) {
      return false;
    }

    const photoStates = this.state.photoStates;
    const thisPhotoState = {
      payload: null,
      localFile: file,
      progress: 5,
      isError: false,
      isSaved: false,
    };

    photoStates.push(thisPhotoState);
    this.setState({ photoStates });

    const key = photoStates.length - 1;
    this.startHandlingUpload(key, file);
  }

  startUploadByPhotoStatesKey(key) {
    if (!key) {
      return false;
    }

    const photoStates = this.state.photoStates;
    const thisPhotoState = photoStates[key];
    const file = thisPhotoState.localFile;

    thisPhotoState.progress = 5;
    thisPhotoState.isError = false;
    thisPhotoState.isSaved = false;

    this.setState({ photoStates });
    this.startHandlingUpload(key, file);
  }

  startHandlingUpload(key, file) {
    const photoStates = this.state.photoStates;
    const thisPhotoState = photoStates[key];

    PhotosAPI.uploadForKey(
      this.UPLOAD_KEY_COMMUNITY,
      [file],
      (completeEvent) => {
        if (completeEvent.target.status !== 200) {
          thisPhotoState.isError = true;
          photoStates[key] = thisPhotoState;
          this.setState({ photoStates });
          return;
        }
        thisPhotoState.payload = JSON.parse(
          completeEvent.target.responseText
        ).photos[0];
        thisPhotoState.localFile = null;
        thisPhotoState.progress = null;

        photoStates[key] = thisPhotoState;
        this.setState({ photoStates });
        this.saveStateToProperty();
      },
      (progressEvent) => {
        thisPhotoState.progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        photoStates[key] = thisPhotoState;
        this.setState({ photoStates });
      },
      () => {
        thisPhotoState.isError = true;
        photoStates[key] = thisPhotoState;
        this.setState({ photoStates });
      }
    );
  }

  removePhotoFromProperty(key) {
    const photoStates = this.state.photoStates;
    const { isSaved } = photoStates[key];

    photoStates.splice(key, 1);
    this.setState({ photoStates });

    if (isSaved) {
      this.saveStateToProperty();
    }
  }

  retryPhotoUpload(key) {
    const photoStates = this.state.photoStates;
    const thisPhotoState = photoStates[key];
    if (thisPhotoState.payload) {
      this.saveStateToProperty();
    } else {
      this.startUploadByPhotoStatesKey(key);
    }
  }

  handleSort(sortedList) {
    this.setState({
      photoStates: sortedList.map((draggableObject) => {
        return draggableObject.photoState;
      }),
    });
    this.saveStateToProperty();
  }

  saveStateToProperty() {
    const requestData = {
      coverPhoto: this.props.property.data.cover_photo,
      photos: [],
    };

    requestData.photos = this.state.photoStates
      .filter((photoState) => {
        return photoState.payload ? true : false;
      })
      .map((photoState) => {
        return photoState.payload;
      });

    PhotosAPI.updatePhotos(
      this.props.property.id,
      requestData,
      this.markPhotoStatesAsSaved.bind(this)
    );
  }

  markPhotoStatesAsSaved() {
    const propertyPhotos = [];

    const photoStates = this.state.photoStates.map((photoState) => {
      const updatedPhotoState = { ...photoState };
      if (updatedPhotoState.payload && !updatedPhotoState.isError) {
        updatedPhotoState.isSaved = true;
        propertyPhotos.push(updatedPhotoState.payload);
      }
      return updatedPhotoState;
    });

    this.setState({ photoStates });
    this.props.updatePropertyPhotos(propertyPhotos);
  }

  updatePhotDescription = (photoIndex) => (photoDescription) => {
    const updatedPhotoStates = this.state.photoStates.map(
      (photoState, index) => {
        if (index === photoIndex) {
          return {
            ...photoState,
            payload: {
              ...photoState.payload,
              description: photoDescription,
            },
          };
        }
        return photoState;
      }
    );
    this.setState(
      { photoStates: updatedPhotoStates },
      this.saveStateToProperty
    );
  };
}

PropertyPhotos.propTypes = {
  property: PropTypes.shape({
    data: PropTypes.shape({
      cover_photo: PropTypes.string,
      photos: PropTypes.arrayOf(
        PropTypes.shape({
          clUrl: PropTypes.string,
          thumbUrl: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
    id: PropTypes.number,
    property_name: PropTypes.string,
  }),
  setDialog: PropTypes.func,
  setImageToPreview: PropTypes.func,
  updatePropertyPhotos: PropTypes.func,
};

export default PropertyPhotos;
