import React, { Component } from 'react';
import { PageContainer, Alert, Toaster } from '@knockrentals/knock-react';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import IntegrationsAPI from './IntegrationsAPI';
import PropertyInfo from './PropertyInfo';
import { Button, EngrainIntegrationModal } from './components';
import { IntegrationVendorNames } from './constants';

import './_IntegrationsPage.scss';
import {
  getCallIntelData,
  getCompanyId,
} from '../Features/CallIntelPage/CallIntelApi/callIntelApi';

class IntegrationsPage extends Component {
  state = {
    isLoading: true,
    properties: [],
    selectedPropertyId: null,
    integrationError: '',
    integrationErrorPropertyId: null,
    showEngrainIntegrationModal: false,
  };

  static contextType = FeatureFlagContext;

  async componentDidMount() {
    await this.loadData();
  }

  getCallIntelProperties = async () => {
    try {
      return await getCallIntelData(getCompanyId());
    } catch {
      Toaster.showToast(
        'We are unable to manage call intel at this time.',
        2000,
        Toaster.ToastClasses.error
      );
      return [];
    }
  };

  loadData = async () => {
    this.setState({ isLoading: true });

    const { company_properties } = await IntegrationsAPI.getProperties();

    const sortedProperties = company_properties.sort((propertyA, propertyB) => {
      return propertyA.location.name > propertyB.location.name ? 1 : -1;
    });

    const selectedPropertyId =
      this.state.selectedPropertyId ||
      (sortedProperties && sortedProperties.length
        ? sortedProperties[0].community_id
        : 'all');

    const callIntelProperties = await this.getCallIntelProperties();

    const callIntelStandAloneMap = callIntelProperties.reduce(
      (standAloneMap, property) => {
        standAloneMap[property.property_id] = property.is_standalone;
        return standAloneMap;
      },
      {}
    );

    this.setState({
      isLoading: false,
      properties: sortedProperties.map((property) => ({
        ...property,
        isCallIntelStandAlone: callIntelStandAloneMap[property.property_id],
      })),
      engrainPropertyMapping: this.getEngrainPropertyMapping(sortedProperties),
      selectedPropertyId,
    });
  };

  render() {
    const {
      showEngrainIntegrationModal,
      engrainPropertyMapping,
      selectedPropertyId,
      properties,
    } = this.state;

    return (
      <PageContainer isLoading={this.state.isLoading}>
        <h1>Properties</h1>

        <div className="knock-react-flex knock-react-flex-row">
          <div>
            <div>Property</div>
            <select
              value={selectedPropertyId}
              onChange={this.onPropertyChanged.bind(this)}
            >
              <option value={'all'}>All</option>
              {this.renderPropertyOptions()}
            </select>
            <div>
              {this.context.isToursSiteMapEnabled && (
                <Button
                  className="edit-engrain-button"
                  onClick={this.toggleEngrainIntegrationModal}
                  iconType="pencil"
                >
                  Edit Engrain Integration
                </Button>
              )}
            </div>
          </div>
        </div>

        <EngrainIntegrationModal
          show={showEngrainIntegrationModal}
          onHide={this.toggleEngrainIntegrationModal}
          reloadData={this.loadData}
          propertyMapping={engrainPropertyMapping}
          properties={properties}
        />

        {this.renderProperties()}
      </PageContainer>
    );
  }

  // Structure the property data a little nicer for the EngrainModal usage
  getEngrainPropertyMapping = (company_properties) => {
    return company_properties.map((property) => {
      const {
        location: { name: community_name },
        secondary_integrations,
        property_id,
      } = property;

      const mappedProperty = {
        community_name,
        property_id,
      };

      if (secondary_integrations && secondary_integrations.length) {
        const engrainIntegration = secondary_integrations.find(
          ({ vendor }) => vendor === IntegrationVendorNames.ENGRAIN
        );

        if (engrainIntegration) {
          const { credential_id, engrain_unitmap_id } = engrainIntegration;
          mappedProperty.credential_id = credential_id;
          mappedProperty.engrain_unitmap_id = engrain_unitmap_id;
        }
      }
      return mappedProperty;
    });
  };

  onPropertyChanged(event) {
    this.setState({ selectedPropertyId: event.target.value });
  }

  renderPropertyOptions() {
    return this.state.properties.map((property) => {
      return (
        <option
          key={'property-' + property.community_id}
          value={property.community_id}
        >
          {property.location.name}
        </option>
      );
    });
  }

  toggleEngrainIntegrationModal = () => {
    this.setState({
      showEngrainIntegrationModal: !this.state.showEngrainIntegrationModal,
    });
  };

  renderProperties() {
    const { properties, selectedPropertyId } = this.state;

    if (properties.length === 0) {
      return <Alert>No properties to display.</Alert>;
    }

    if (selectedPropertyId === 'all') {
      return properties.map((property) => {
        return this.renderProperty(property);
      });
    }

    return this.renderProperty(
      properties.find(
        (property) => property.community_id === this.state.selectedPropertyId
      )
    );
  }

  renderProperty(property) {
    const { integrationError, integrationErrorPropertyId } = this.state;
    const { property_id } = property;
    const errorMessage =
      property_id === integrationErrorPropertyId ? integrationError : '';

    return (
      <PropertyInfo
        key={property.community_id}
        property={property}
        integrationErrorMessage={errorMessage}
        onPropertyChanged={this.onPropertyUpdated.bind(this)}
        onIntegrationUpdated={this.onIntegrationUpdated.bind(this)}
        onStartAddIntegration={this.onStartAddIntegration.bind(this)}
        onEndAddIntegration={this.onEndAddIntegration.bind(this)}
      />
    );
  }

  onPropertyUpdated(updatedProperty) {
    IntegrationsAPI.updateProperty(updatedProperty.property_id, updatedProperty)
      .then(() => {
        const updatedProperties = this.state.properties.slice();
        const updatedPropertyIndex = updatedProperties.findIndex(
          (property) => property.community_id === updatedProperty.community_id
        );

        updatedProperties[updatedPropertyIndex] = updatedProperty;

        this.setState({
          properties: updatedProperties,
        });

        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      })
      .catch(() => {
        Toaster.showToast(
          'Error saving property!',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  onIntegrationUpdated() {
    this.loadData();
  }

  onStartAddIntegration() {
    this.setState({ isLoading: true });
  }

  onEndAddIntegration(propertyId, errorMessage) {
    this.setState({
      isLoading: false,
      integrationError: errorMessage,
      integrationErrorPropertyId: propertyId,
    });
  }
}

export default IntegrationsPage;
