import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import { makeStyles, Switch } from '@knockrentals/knock-shared-web';
import { LABELS } from './constants';

const useStyles = makeStyles({
  switchLabel: {
    '&.MuiFormControlLabel-root': {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 0,
    },
  },
});

const SettingsSwitch = ({ handleOnChange, isChecked, name }) => {
  const classes = useStyles();
  return (
    <div className="settings-switch">
      <FormControlLabel
        className={classes.switchLabel}
        control={
          <Switch
            checked={isChecked || false}
            color="primary"
            name={name}
            onChange={handleOnChange}
          />
        }
        label={LABELS[name]}
        labelPlacement="start"
      />
    </div>
  );
};

SettingsSwitch.propTypes = {
  handleOnChange: PropTypes.func,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
};

export default SettingsSwitch;
