import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalContent,
} from '@knockrentals/knock-react';
import { Formik } from 'formik';
import { VALIDATION_ERROR_MESSAGES } from './constants';

import './_phoneRelayEditor.scss';

class AddSourceTrackingNumbers extends Component {
  cleanAvailableTrackingNums = this.props.availableTrackingNums.map(
    (trackingNumber) => trackingNumber.slice(-10)
  );

  render() {
    const matchedNumbers = this.props.sourcesToAddTracking.map((source, i) => {
      return (
        <tr key={i}>
          <td key={source.source_id}> {source.title} </td>
          <td key={i}>
            {' '}
            {this.props.availableTrackingNums[i]} ({this.props.countryCode})
          </td>
        </tr>
      );
    });

    return (
      <Modal className="phone-relay-editor">
        <Formik
          initialValues={{
            singleForwardingNumber: '',
            forwardsTo: '',
            residentForwardsTo: '',
            sourceId: null,
            greetingRepeat: 0,
            residentGreetingRepeat: 0,
            greetingDelay: 5,
            residentGreetingDelay: 1,
            recordCalls: true,
            relayPhoneNumber: null,
            relayPhoneId: null,
          }}
          onSubmit={this.onFormSubmit.bind(this)}
          validate={this.validate}
        >
          {(props) => (
            <form
              onSubmit={props.handleSubmit}
              onKeyPress={this.pressEnterSelectTrackingNumber.bind(this, props)}
              autoComplete="off"
            >
              <ModalHeader>
                <h3>Configure Tracking Numbers</h3>
              </ModalHeader>
              <ModalContent>
                <div>
                  <div className="knock-react-flex">
                    <table className="wire-frame-table">
                      <thead>
                        <tr>
                          <th>
                            <strong>Source</strong>
                          </th>
                          <th>
                            <strong>Tracking number</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{matchedNumbers}</tbody>
                    </table>
                  </div>
                  <div className="knock-react-flex">
                    <div>
                      <strong>Record calls: </strong>
                    </div>
                    <div>
                      <input
                        name="recordCalls"
                        type="checkbox"
                        checked={props.values.recordCalls}
                        onChange={(e) =>
                          props.setFieldValue('recordCalls', e.target.checked)
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  {this.props.internalMode ? (
                    <Fragment>
                      <div
                        className={classnames(
                          'knock-react-flex',
                          props.errors.forwardsTo && 'error'
                        )}
                      >
                        <div id="forwardsTo">
                          <strong>Prospect: </strong>
                        </div>
                        <div>
                          <input
                            aria-labelledby="forwardsTo"
                            className="knock-react-input"
                            value={props.values.forwardsTo}
                            name="forwardsTo"
                            placeholder="Phone number"
                            onChange={props.handleChange}
                          />
                          {props.errors.forwardsTo && (
                            <div className="knock-react-form-hint knock-react-form-hint-error">
                              {props.errors.forwardsTo}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="knock-react-flex">
                        <div>
                          <strong>Greeting: </strong>
                        </div>
                        <div>
                          <select
                            name="greetingRepeat"
                            value={props.values.greetingRepeat}
                            onChange={props.handleChange}
                          >
                            {this.props.greetingRepeatOptions}
                          </select>
                        </div>
                      </div>
                      <div className="knock-react-flex">
                        <div>
                          <strong>Greeting delay: </strong>
                        </div>
                        <div>
                          <select
                            name="greetingDelay"
                            value={props.values.greetingDelay}
                            onChange={props.handleChange}
                          >
                            {this.props.greetingDelayOptions}
                          </select>
                        </div>
                      </div>
                      <div
                        className={classnames(
                          'knock-react-flex',
                          props.errors.residentForwardsTo && 'error'
                        )}
                      >
                        <div id="residentForwardsTo">
                          <strong>Resident: </strong>
                        </div>
                        <div>
                          <input
                            aria-labelledby="residentForwardsTo"
                            className="knock-react-input"
                            value={props.values.residentForwardsTo}
                            name="residentForwardsTo"
                            placeholder="Phone number"
                            onChange={props.handleChange}
                          />
                          {props.errors.residentForwardsTo && (
                            <div className="knock-react-form-hint knock-react-form-hint-error">
                              {props.errors.residentForwardsTo}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="knock-react-flex">
                        <div>
                          <strong>Greeting: </strong>
                        </div>
                        <div>
                          <select
                            name="residentGreetingRepeat"
                            value={props.values.residentGreetingRepeat}
                            onChange={props.handleChange}
                          >
                            {this.props.greetingRepeatOptions}
                          </select>
                        </div>
                      </div>
                      <div className="knock-react-flex">
                        <div>
                          <strong>Greeting delay: </strong>
                        </div>
                        <div>
                          <select
                            value={props.values.residentGreetingDelay}
                            onChange={props.handleChange}
                            name="residentGreetingDelay"
                          >
                            {this.props.greetingDelayOptions}
                          </select>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <div
                      className={classnames(
                        'knock-react-flex',
                        props.errors.singleForwardingNumber && 'error'
                      )}
                    >
                      <div id="singleForwardingNumber">
                        <strong>Prospect & Resident: </strong>
                      </div>
                      <div>
                        <input
                          aria-labelledby="singleForwardingNumber"
                          className="knock-react-input"
                          value={props.values.singleForwardingNumber}
                          name="singleForwardingNumber"
                          placeholder="Phone number"
                          onChange={props.handleChange}
                        />
                        {props.errors.singleForwardingNumber && (
                          <div className="knock-react-form-hint knock-react-form-hint-error">
                            {props.errors.singleForwardingNumber}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </ModalContent>
              <ModalFooter>
                <button
                  className="knock-react-button"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </button>
                <button
                  className="knock-react-button"
                  disabled={!props.isValid}
                  type="submit"
                >
                  Save
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  //Prevent the enter key from bubbling up and closing the form or submitting invalid form data
  pressEnterSelectTrackingNumber(event, formProps) {
    if (event.charCode === 13) {
      event.stopPropagation();
      event.preventDefault();

      this.onFormSubmit(formProps.values);
    }
  }

  onFormSubmit(values) {
    const replaceDigitsRegex = /\D/g;

    this.props.onSubmit({
      ...values,
      singleForwardingNumber: values.singleForwardingNumber.replace(
        replaceDigitsRegex,
        ''
      ),
      forwardsTo: values.forwardsTo.replace(replaceDigitsRegex, ''),
      residentForwardsTo: values.residentForwardsTo.replace(
        replaceDigitsRegex,
        ''
      ),
    });
  }

  validate = (values) => {
    const errors = {};
    const cleanSingleForwardingNumber = values.singleForwardingNumber.replace(
      /\D/g,
      ''
    );
    const cleanForwardsTo = values.forwardsTo.replace(/\D/g, '');
    const cleanResidentForwardsTo = values.residentForwardsTo.replace(
      /\D/g,
      ''
    );

    if (this.props.internalMode) {
      if (!cleanForwardsTo) {
        errors.forwardsTo = 'Required';
      }

      if (cleanForwardsTo.length !== 10 || !/[0-9]{10}/.test(cleanForwardsTo)) {
        errors.forwardsTo = 'Invalid phone number';
      }

      if (this.cleanAvailableTrackingNums.includes(cleanForwardsTo)) {
        errors.forwardsTo = VALIDATION_ERROR_MESSAGES.PROSPECT_SAME_AS_TRACKING;
      }

      if (!cleanResidentForwardsTo) {
        errors.residentForwardsTo = 'Required';
      }

      if (
        cleanResidentForwardsTo.length !== 10 ||
        !/[0-9]{10}/.test(cleanResidentForwardsTo)
      ) {
        errors.residentForwardsTo = 'Invalid phone number';
      }

      if (this.cleanAvailableTrackingNums.includes(cleanResidentForwardsTo)) {
        errors.residentForwardsTo =
          VALIDATION_ERROR_MESSAGES.RESIDENT_SAME_AS_TRACKING;
      }
    } else {
      if (!cleanSingleForwardingNumber) {
        errors.singleForwardingNumber = 'Required';
      }

      if (
        cleanSingleForwardingNumber.length !== 10 ||
        !/[0-9]{10}/.test(cleanSingleForwardingNumber)
      ) {
        errors.singleForwardingNumber = 'Invalid phone number';
      }

      if (
        this.cleanAvailableTrackingNums.includes(cleanSingleForwardingNumber)
      ) {
        errors.singleForwardingNumber =
          VALIDATION_ERROR_MESSAGES.SAME_AS_TRACKING;
      }
    }

    return errors;
  };
}

AddSourceTrackingNumbers.propTypes = {
  availableTrackingNums: PropTypes.arrayOf(PropTypes.string),
  countryCode: PropTypes.string,
  greetingDelayOptions: PropTypes.arrayOf(PropTypes.node),
  greetingRepeatOptions: PropTypes.arrayOf(PropTypes.node),
  internalMode: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  sourcesToAddTracking: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, title: PropTypes.string })
  ),
};

export default AddSourceTrackingNumbers;
