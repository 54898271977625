import React from 'react';

import PropertyResponses from './Components/PropertyResponses';
import { Alert } from '@knockrentals/knock-react';
import * as VirtualAgentAPI from '../VirtualAgentAPI';
import { useFeatureFlagContext } from '../../../Context/FeatureFlagContext';
import { makeStyles, Text } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  disclaimerText: {
    marginTop: '25px',
    marginBottom: '25px',
    marginRight: '30%',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: 'normal',
  },
});

const getRenterAIDisclaimerMessage = (properties, renterAIProperties) => {
  const disclaimerProperties = [];

  properties.forEach(property => {
    if (renterAIProperties.includes(property.id)) {
      disclaimerProperties.push(property.name)
    }
  });

  return disclaimerProperties.length ? 
  `The following properties are participating in the new 
  full GenAI beta and no longer need to manage responses: ${
    disclaimerProperties.join(', ')}` : ''
}

const VirtualAgentLeasingResponses = ({
  properties,
  intents,
  onConfirmation,
  currentTab
}) => {
  const classes = useStyles();
  const { renterAIFlag } = useFeatureFlagContext();
  const renterAIProperties = renterAIFlag?.properties || [];
  const renterAIDisclaimerMessage = renterAIProperties.length ? 
  getRenterAIDisclaimerMessage(properties, renterAIProperties) : '';

  return (
    <React.Fragment>
      {renterAIDisclaimerMessage && (
        <Text variant='h6' className={classes.disclaimerText}>
          {renterAIDisclaimerMessage}
        </Text>
      )}
      {properties.length ? (
        properties.map((property) => (
          <PropertyResponses
            key={property.id}
            property={property}
            intents={intents}
            onConfirmation={onConfirmation}
            getResponses={VirtualAgentAPI.getLeasingResponsesV2}
            saveResponses={VirtualAgentAPI.saveLeasingResponsesV2}
            currentTab={currentTab}
          />
        ))
      ) : (
        <Alert>No properties to display.</Alert>
      )}
    </React.Fragment>
  );
};

export default VirtualAgentLeasingResponses;
