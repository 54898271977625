import React from 'react';

export const KnockSVGLogo = () => {
  return (
    <svg
      width="50"
      height="16"
      viewBox="0 0 50 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11079_375)">
        <path
          d="M8.0488 14.0275L8.041 14.0192L3.63494 9.78002L7.05369 6.72243C7.45144 6.35933 7.31576 6.04308 7.13177 5.84151L6.65691 5.32126C6.40068 5.04064 6.07858 5.04015 5.77257 5.31931L4.9634 6.0582L2.07223 8.65069V1.66484C2.07223 1.12604 1.71791 1.01282 1.4202 1.01282H0.652025C0.237676 1.01282 0 1.25049 0 1.66484V14.2749C0 14.6892 0.237676 14.9269 0.652025 14.9269H1.4202C1.83455 14.9269 2.07223 14.6892 2.07223 14.2749V10.9508L5.81454 14.5253C6.00635 14.7537 6.18643 14.9264 6.54661 14.9264H7.68961C8.04246 14.9264 8.16935 14.7239 8.20791 14.6365C8.26696 14.5023 8.28746 14.2871 8.0488 14.027V14.0275Z"
          fill="#41AAF5"
        />
        <path
          d="M49.632 13.8914L49.6242 13.8831L45.2182 9.64391L48.6369 6.58632C49.0346 6.22322 48.899 5.90697 48.715 5.70541L48.2401 5.18515C47.9839 4.90453 47.6618 4.90404 47.3558 5.1832L46.5466 5.9221L43.6554 8.51458V1.52873C43.6554 0.989935 43.3011 0.876709 43.0034 0.876709H42.2352C41.8209 0.876709 41.5832 1.11439 41.5832 1.52873V14.1388C41.5832 14.5531 41.8209 14.7908 42.2352 14.7908H43.0034C43.4178 14.7908 43.6554 14.5531 43.6554 14.1388V10.8147L47.3978 14.3892C47.5896 14.6176 47.7696 14.7903 48.1298 14.7903H49.2728C49.6252 14.7903 49.7526 14.5878 49.7911 14.5004C49.8502 14.3662 49.8707 14.151 49.632 13.8909V13.8914Z"
          fill="#41AAF5"
        />
        <path
          d="M14.4056 4.90698C13.0991 4.90698 12.2016 5.58732 11.6232 6.28961L11.5681 5.66687L11.5461 5.61464C11.4822 5.46189 11.3333 5.10366 10.9048 5.10366H10.4583C10.0556 5.10366 9.8243 5.34134 9.8243 5.75569V14.2755C9.8243 14.6898 10.0551 14.9275 10.4583 14.9275H11.2621C11.6652 14.9275 11.896 14.6898 11.896 14.2755V9.77424C11.896 8.3228 12.6476 6.85379 14.0844 6.85379C15.5212 6.85379 16.2367 7.87282 16.2367 9.6493V14.2755C16.2367 14.6898 16.4675 14.9275 16.8707 14.9275H17.6745C18.0776 14.9275 18.3084 14.6898 18.3084 14.2755V9.29205C18.3084 7.84744 17.9185 6.72397 17.1498 5.95237C16.4685 5.26862 15.5193 4.90698 14.4056 4.90698Z"
          fill="#41AAF5"
        />
        <path
          d="M24.7828 4.90698C21.9907 4.90698 19.7194 7.20274 19.7194 10.0241C19.7194 12.8455 21.9907 15.1232 24.7828 15.1232C27.5749 15.1232 29.8463 12.8357 29.8463 10.0241C29.8463 7.2125 27.5749 4.90698 24.7828 4.90698ZM24.7828 13.1944C23.1332 13.1944 21.7911 11.7723 21.7911 10.0241C21.7911 8.27594 23.1332 6.85379 24.7828 6.85379C26.4324 6.85379 27.7926 8.27594 27.7926 10.0241C27.7926 11.7723 26.4422 13.1944 24.7828 13.1944Z"
          fill="#41AAF5"
        />
        <path
          d="M39.0273 12.356C38.9624 12.274 38.8004 12.0871 38.5432 12.0871C38.3909 12.0871 38.247 12.1534 38.1166 12.2838C37.5491 12.8513 36.7384 13.1769 35.8926 13.1769C34.205 13.1769 32.8829 11.7918 32.8829 10.0241C32.8829 8.25642 34.2333 6.85379 35.8926 6.85379C36.7384 6.85379 37.5491 7.17931 38.1166 7.7469C38.2474 7.87721 38.3909 7.94359 38.5432 7.94359C38.8004 7.94359 38.9624 7.75667 39.0273 7.67468L39.4431 7.24129L39.4529 7.23104C39.5447 7.12807 39.6647 6.96652 39.6515 6.7552C39.6379 6.539 39.4905 6.39942 39.4295 6.34525C38.4841 5.4043 37.2552 4.90698 35.8746 4.90698C33.0922 4.90698 30.8287 7.20274 30.8287 10.0241C30.8287 11.3853 31.3499 12.6644 32.2958 13.6254C33.2465 14.5912 34.5173 15.1232 35.8746 15.1232C37.2318 15.1232 38.4837 14.6259 39.429 13.6849C39.49 13.6308 39.6374 13.4917 39.6511 13.2755C39.6642 13.0641 39.5442 12.9026 39.4524 12.7996L39.0268 12.356H39.0273Z"
          fill="#41AAF5"
        />
      </g>
      <defs>
        <clipPath id="clip0_11079_375">
          <rect
            width="49.8297"
            height="14.2465"
            fill="white"
            transform="translate(0 0.876709)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
