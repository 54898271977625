import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputText,
  makeStyles,
  ThemeProvider,
} from '@knockrentals/knock-shared-web';

export const PHOTO_DESCRIPTION_LABEL = 'Description';
export const PHOTO_DESCRIPTION_PLACEHOLDER = 'ex. Bedroom, 1st floor unit 111';

const useStyles = makeStyles({
  photoDescription: {
    '& .MuiInput-input': {
      fontSize: '12px',
    },
  },
});

const PhotoDescription = ({ description = '', updateDescription }) => {
  const [photoDescription, setPhotoDescription] = useState(description);
  const classes = useStyles();

  const handleOnChange = (e) => {
    setPhotoDescription(e.target.value);
  };

  const handleOnBlur = () => {
    const trimmedPhotoDescription = photoDescription.trim();
    if (trimmedPhotoDescription !== description.trim()) {
      updateDescription(trimmedPhotoDescription);
    }
  };

  return (
    <ThemeProvider>
      <InputText
        className={classes.photoDescription}
        fullWidth
        label={PHOTO_DESCRIPTION_LABEL}
        margin="normal"
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        placeholder={PHOTO_DESCRIPTION_PLACEHOLDER}
        requiredMessage="required"
        value={photoDescription}
      />
    </ThemeProvider>
  );
};

PhotoDescription.propTypes = {
  description: PropTypes.string,
  updateDescription: PropTypes.func,
};

export default PhotoDescription;
