import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import { PageContainer, Alert } from '@knockrentals/knock-react';
import MigrationAlert from './MigrationAlert';
import PhotosAPI from './PhotosAPI';
import PhotosDialogModal from './PhotosDialogModal';
import PropertyPhotos from './PropertyPhotos';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import './_propertyPhotos.scss';

class PhotosPage extends Component {
  state = {
    isLoading: true,
    properties: [],
    dialog: null,
    imageToPreview: null,
    selectedPropertyId: null,
  };

  componentDidMount() {
    PhotosAPI.getPhotos().then((response) => {
      const sortedProperties = response.company_photos.sort(
        (propertyA, propertyB) => {
          return propertyA.property_name > propertyB.property_name ? 1 : -1;
        }
      );

      this.setState({
        properties: sortedProperties,
        selectedPropertyId: sortedProperties
          ? sortedProperties[0].property_id
          : null,
        isLoading: false,
      });
    });
  }

  render() {
    return (
      <PageContainer
        className="doorway-page-container property-photos-main"
        isLoading={this.state.isLoading}
      >
        {this.context.isAdminPhotosTabEnabled && <MigrationAlert />}

        <h1>Photos</h1>
        {this.state.imageToPreview ? (
          <Lightbox
            mainSrc={this.state.imageToPreview}
            onCloseRequest={this.closeImagePreview.bind(this)}
          />
        ) : null}
        {this.state.dialog ? (
          <PhotosDialogModal
            title={this.state.dialog.title}
            body={this.state.dialog.body}
            clearDialog={this.clearDialog.bind(this)}
            onConfirm={
              this.state.dialog.onConfirm
                ? this.state.dialog.onConfirm.bind(this)
                : null
            }
            onCancel={
              this.state.dialog.onCancel
                ? this.state.dialog.onCancel.bind(this)
                : null
            }
          />
        ) : null}
        <div className="knock-react-flex knock-react-flex-row">
          <div>
            <div>Property</div>
            <select
              value={this.state.selectedPropertyId}
              onChange={this.onPropertyChanged.bind(this)}
            >
              {this.renderPropertyOptions()}
            </select>
          </div>
        </div>
        {this.renderProperties()}
      </PageContainer>
    );
  }

  closeImagePreview() {
    this.setImageToPreview(null);
  }

  setImageToPreview(url) {
    this.setState({
      imageToPreview: url,
    });
  }

  clearDialog() {
    this.setState({
      dialog: null,
    });
  }

  setDialog(title, body, onConfirm, onCancel) {
    this.setState({
      dialog: { title, body, onConfirm, onCancel },
    });
  }

  onPropertyChanged(event) {
    this.setState({
      selectedPropertyId: parseInt(event.target.value, 10),
    });
  }

  setPropertyPhotos = (photos) => {
    const properties = this.state.properties.map((property) => {
      if (property.property_id === this.state.selectedPropertyId) {
        property.photos = photos;
      }
      return property;
    });
    this.setState({ properties });
  };

  renderPropertyOptions() {
    return this.state.properties.map((property) => {
      return (
        <option
          key={'property-' + property.property_id}
          value={property.property_id}
        >
          {property.property_name}
        </option>
      );
    });
  }

  renderProperties() {
    if (this.state.properties.length === 0) {
      return <Alert>No properties to display.</Alert>;
    }

    const selectedProperty = this.state.properties.find(
      (property) => property.property_id === this.state.selectedPropertyId
    );

    return [
      <PropertyPhotos
        key={selectedProperty.property_id}
        property={selectedProperty}
        setDialog={this.setDialog.bind(this)}
        setImageToPreview={this.setImageToPreview.bind(this)}
        setPropertyPhotos={this.setPropertyPhotos}
      />,
    ];
  }
}

export default PhotosPage;

PhotosPage.contextType = FeatureFlagContext;
