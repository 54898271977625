import { AuthenticationService } from '@knockrentals/knock-react';
import { useAppContext } from '../../Context/AppContext';
import {
  ACCESS_TOKEN_LOGIN_TYPES,
  ACCESS_TOKEN_LOGIN_TYPE,
} from '../../constants';

export const OMNIBAR_SILENT_REFRESH_PATH = '/omnibar-silent-refresh.html';

const logout = () => {
  setTimeout(() => {
    localStorage.removeItem(ACCESS_TOKEN_LOGIN_TYPE);
    AuthenticationService.clearToken();
  });
};

export const useOmnibar = () => {
  const { companyName, isInternalMode, username, userRole } = useAppContext();

  const isUnifiedUser =
    !isInternalMode &&
    localStorage.getItem(ACCESS_TOKEN_LOGIN_TYPE) ===
      ACCESS_TOKEN_LOGIN_TYPES.UNIFIED;

  const silentRefreshUri =
    !isInternalMode && isUnifiedUser
      ? `${window.location.origin}${OMNIBAR_SILENT_REFRESH_PATH}`
      : '';

  const knockUserInfo = {
    username,
    userRole,
  };

  return {
    companyName,
    isUnifiedUser,
    logout,
    silentRefreshUri,
    userInfo: isUnifiedUser ? {} : knockUserInfo,
  };
};
