import React, { useEffect, useRef } from 'react';
import { KnockAdminLogo } from '../KnockAdminLogo';
import { useOmnibar } from './use-omnibar';
import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import { UNIFIED_ENVIRONMENT } from '../../config';
import { OMNIBAR_PRODUCT_CODE, UNIFIED_HELP_QUERY } from '../../constants';
import './_OmniBarShell.scss';

const OmniBarShell = () => {
  const { companyName, isUnifiedUser, logout, silentRefreshUri, userInfo } =
    useOmnibar();
  const omnibarRef = useRef();
  const { isOmnibarHelpEnabled } = useFeatureFlagContext();

  useEffect(() => {
    if (omnibarRef.current && !omnibarRef.current.logoutCallback) {
      omnibarRef.current.logoutCallback = logout;
    }
  }, [logout]);

  return (
    <omnibar-shell
      environment={UNIFIED_ENVIRONMENT}
      is-unified-login={isUnifiedUser}
      navigation={false}
      product-code={OMNIBAR_PRODUCT_CODE}
      ref={omnibarRef}
      silent-refresh-uri={silentRefreshUri}
    >
      <div slot="logo">
        <KnockAdminLogo />
      </div>

      <omnibar-scope base={companyName} slot="scope"></omnibar-scope>

      <omnibar-unified-menu
        slot="unified-menu"
        user-name={userInfo.username}
        user-title={userInfo.userRole}
      >
        {isOmnibarHelpEnabled && (
          <omnibar-unified-help
            data-testid="help"
            help-query={UNIFIED_HELP_QUERY}
            slot="help"
          ></omnibar-unified-help>
        )}

        <omnibar-unified-switcher slot="switcher"></omnibar-unified-switcher>
        <omnibar-unified-notifications slot="notifications"></omnibar-unified-notifications>
      </omnibar-unified-menu>
    </omnibar-shell>
  );
};

export default OmniBarShell;
