export const ACCESS_SETTINGS = {
  AD_SPEND_PAGE: 'adSpendPage',
  ADMINS_PAGE: 'adminsPage',
  AI_TEMPLATE_PAGE: 'aiTemplatePage',
  ATTRIBUTES_PAGE: 'attributesPage',
  BRAND_CONTENT_PAGE: 'brandContentPage',
  CROSS_SELL_TAB: 'crossSellTab',
  DOORWAY_PAGE: 'doorwayPage',
  DRIP_CAMPAIGN_PAGE: 'dripCampaignPage',
  INTEGRATIONS_PAGE: 'integrationsPage',
  LMA_ACCESS: 'lmaAccess',
  PHOTOS_PAGE: 'photoPage',
  PRICING_PAGE: 'pricingPage',
  PROPERTIES_PAGE: 'propertiesPage',
  QUICK_REPLIES_PAGE: 'quickRepliesPage',
  REGISTRATION_PAGE: 'registrationPage',
  REFRESH_SPREADSHEET_PAGE: 'refreshSpreadsheetPage',
  SCHEDULING_PAGE: 'schedulingPage',
  SETTINGS_PAGE: 'settingsPage',
  SHEETS_PAGE: 'sheetsPage',
  SOURCES_PAGE: 'sourcesPage',
  SOURCE_LIST_PAGE: 'sourceListPage',
  SSDA_PAGE: 'ssdaPage',
  SYNC_PAGE: 'syncPage',
  TEMPLATES_PAGE: 'templatesPage',
  USERS_PAGE: 'usersPage',
  TOURS_PAGE: 'toursPage',
  VIRTUAL_AGENT_PAGE: 'virtualAgentPage',
};

export const ADMIN_USER_ROLES = {
  ADMIN: 'admin',
  ANALYTICS: 'analytics_user',
  MASTER: 'master',
};

export const ADMIN_USER_LABELS = {
  [ADMIN_USER_ROLES.ADMIN]: 'Admin',
  [ADMIN_USER_ROLES.ANALYTICS]: 'Analytics',
  [ADMIN_USER_ROLES.MASTER]: 'Master',
};

export const SAVED_NOTIFICATION = 'Saved!';

export const CKEDITOR_URL = 'ckeditor-4/ckeditor.js';

export const ACCESS_ROLES = {
  ADMIN: ADMIN_USER_ROLES.ADMIN,
  ANALYTICS: ADMIN_USER_ROLES.ANALYTICS,
  AGENT: 'agent',
};

export const ACCESS_ROLE_LABELS = {
  [ACCESS_ROLES.ADMIN]: ADMIN_USER_LABELS[ACCESS_ROLES.ADMIN],
  [ACCESS_ROLES.ANALYTICS]: ADMIN_USER_LABELS[ACCESS_ROLES.ANALYTICS],
  [ACCESS_ROLES.AGENT]: 'Agent',
};

export const ACCESS_TOKEN_LOGIN_TYPE = 'accessTokenLoginType';
export const ACCESS_TOKEN_LOGIN_TYPES = {
  UNIFIED: 'unified',
};

export const QUERY_STRING_PARAMS = {
  ACCESS_TOKEN: 'accessToken',
  INTERNAL: 'internal',
  LOGIN_TYPE: 'loginType',
};

export const OMNIBAR_PRODUCT_CODE = 'KNCKADM';
export const UNIFIED_HELP_QUERY = `pg=${OMNIBAR_PRODUCT_CODE}-admin&vr=40&Scrver=350`;
