import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@knockrentals/knock-react';

class TransferProperty extends Component {
  state = {
    transferringPropertyInProgress: false,
    leasingTeamPublicId: null,
    defaultManagerOwnerUsername: null,
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  setLeasingTeamPublicId(event) {
    this.setState({ leasingTeamPublicId: event.target.value });
  }

  setDefaultManagerOwnerUsername(event) {
    this.setState({ defaultManagerOwnerUsername: event.target.value });
  }

  submit(event) {
    event.preventDefault();

    this.setState({ transferringPropertyInProgress: true });

    this.props
      .onSubmit(
        this.state.leasingTeamPublicId,
        this.state.defaultManagerOwnerUsername
      )
      .then(() => {
        this.setState({ transferringPropertyInProgress: false });
      });
  }

  cancel(event) {
    event.preventDefault();
    this.props.onCancel();
  }

  renderTransferErrors() {
    if (!this.props.transferErrors) {
      return null;
    }

    return (
      <div>
        <div>
          <strong>Invalid</strong>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal className="user-editor-container">
        <form onSubmit={this.submit} name="transferPropertyForm">
          <ModalHeader>
            <h2>Transfer {this.props.property?.data.location.name}</h2>
          </ModalHeader>
          <ModalContent>
            <div className="knock-react-flex">
              <div>
                <strong>Leasing team public ID to transfer to: </strong>
              </div>
              <div>
                <input
                  aria-label="Leasing team public id"
                  className="knock-react-input"
                  onChange={this.setLeasingTeamPublicId.bind(this)}
                  type="text"
                  value={this.state.leasingTeamPublicId || ''}
                />
              </div>
            </div>
            <div className="knock-react-flex">
              <div>
                <strong>Default manager owner username: </strong>
              </div>
              <div>
                <input
                  aria-label="Default Manager Owner Username"
                  className="knock-react-input"
                  onChange={this.setDefaultManagerOwnerUsername.bind(this)}
                  type="text"
                  value={this.state.defaultManagerOwnerUsername || ''}
                />
              </div>
            </div>
            {this.renderTransferErrors()}
          </ModalContent>
          <ModalFooter>
            <button
              className="knock-react-button"
              disabled={this.state.transferringPropertyInProgress}
            >
              Transfer
            </button>
            <button
              className="knock-react-button"
              onClick={this.cancel.bind(this)}
            >
              Cancel
            </button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default TransferProperty;
