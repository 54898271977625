import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Redirect, Route } from 'react-router-dom';
import { AuthenticationService } from '@knockrentals/knock-react';
import { AccessInvalidPage } from '../Pages/AccessInvalid';
import { useAppContext } from '../Context/AppContext/AppContext';
import { useFeatureFlagContext } from '../Context/FeatureFlagContext';
import { ACCESS_SETTINGS, QUERY_STRING_PARAMS } from '../constants';

const ProtectedRoute = ({
  accessSetting,
  component,
  isAuthenticationBypassed,
  path,
  ...rest
}) => {
  const { isAuthorized } = useAppContext();
  const { isEnhancedAccessEnabled } = useFeatureFlagContext();

  const { location } = rest;
  const queryParams = queryString.parse(location?.search);
  if (queryParams[QUERY_STRING_PARAMS.ACCESS_TOKEN]) {
    AuthenticationService.clearRefreshToken();
    AuthenticationService.setToken(
      queryParams[QUERY_STRING_PARAMS.ACCESS_TOKEN]
    );
  }

  const redirectToLogin = {
    pathname: '/login',
    state: { from: location },
  };

  const isAuthenticated =
    AuthenticationService.isAuthenticated() || isAuthenticationBypassed;

  const isAccessAuthorized =
    (!isEnhancedAccessEnabled &&
      accessSetting !== ACCESS_SETTINGS.ATTRIBUTES_PAGE) ||
    !accessSetting ||
    isAuthorized(accessSetting);

  if (isAuthenticated && isAccessAuthorized) {
    return <Route path={path} component={component} {...rest} />;
  }

  if (!isAuthenticated && location?.pathname === path) {
    return <Redirect to={redirectToLogin} />;
  }

  if (!isAccessAuthorized) {
    return <Route {...rest} component={AccessInvalidPage} />;
  }

  return null;
};

ProtectedRoute.propTypes = {
  accessSetting: PropTypes.string,
  component: PropTypes.func.isRequired,
  computedHash: PropTypes.object,
  history: PropTypes.object,
  isAuthenticationBypassed: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  path: PropTypes.string.isRequired,
};

export default ProtectedRoute;
