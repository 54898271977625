import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  VerticalNavbar,
  VerticalNavbarLink,
  AuthenticationService,
} from '@knockrentals/knock-react';
import CookieService from '../../Services/CookieService';
import SupportNavbarLink from '../../Components/SupportNavbarLink';
import NavHeader from './NavHeader';
import ProtectedNavBarLink from './ProtectedNavBarLink';
import { useAppContext } from '../../Context/AppContext';
import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import { isMasterRole } from '../utils';
import { BASE_ANALYTICS_URI } from '../../config';
import { ACCESS_SETTINGS } from '../../constants';

export const IS_SHEETS_LINK_VISIBLE = false;

const NavigationBar = () => {
  const { isInternalMode } = useAppContext();
  const {
    isCallIntelStandaloneEnabled,
    isOmnibarEnabled,
    isUseCookieForTokenEnabled,
  } = useFeatureFlagContext();

  const openAnalytics = async () => {
    const tokenValue = AuthenticationService.getToken();
    if (isUseCookieForTokenEnabled) {
      CookieService.setTokenCookie(tokenValue, 'accessToken');
      window.open(BASE_ANALYTICS_URI + '/login', 'noopener,noreferrer');
    } else {
      window.open(
        BASE_ANALYTICS_URI + '/login?accessToken=' + tokenValue,
        'noopener,noreferrer'
      );
    }
  };

  return (
    <VerticalNavbar>
      {!isOmnibarEnabled && <NavHeader />}

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.INTEGRATIONS_PAGE}
        path="/integrations"
      >
        <i className="fa fa-cogs fa-fw" /> Integrations
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.SOURCES_PAGE}
        path="/sources"
      >
        <i className="fa fa-share-alt fa-fw" /> Sources
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.USERS_PAGE}
        path="/users"
      >
        <i className="fa fa-users fa-fw" /> Users
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.PROPERTIES_PAGE}
        path="/properties"
      >
        <i className="fa fa-building fa-fw" /> Properties
      </ProtectedNavBarLink>

      {isInternalMode && (
        <NavLink to="/syndication">
          <VerticalNavbarLink>
            <i className="fa fa-code-fork fa-fw" /> Syndication
          </VerticalNavbarLink>
        </NavLink>
      )}

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.PRICING_PAGE}
        path="/pricing"
      >
        <i className="fa fa-dollar fa-fw" /> Pricing
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.SCHEDULING_PAGE}
        path="/scheduling"
      >
        <i className="fa fa-sliders fa-fw" /> Scheduling
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.BRAND_CONTENT_PAGE}
        path="/brand-content"
      >
        <i className="fa fa-palette fa-fw" /> Brand Content
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.DOORWAY_PAGE}
        path="/doorway"
      >
        <i className="fa fa-calendar-days fa-fw" /> Doorway
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.DRIP_CAMPAIGN_PAGE}
        path="/drip-campaign"
      >
        <i className="fa fa-list-check fa-fw" /> Drip Campaign
      </ProtectedNavBarLink>

      {IS_SHEETS_LINK_VISIBLE && (
        <ProtectedNavBarLink
          accessSetting={ACCESS_SETTINGS.SHEETS_PAGE}
          path="/sheets"
        >
          <i className="fa fa-upload fa-fw" /> Sheets
        </ProtectedNavBarLink>
      )}

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.TEMPLATES_PAGE}
        path="/templates"
      >
        <i className="fa fa-edit fa-fw" /> Templates
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.AI_TEMPLATE_PAGE}
        path="/ai-templates"
      >
        <i className="fa fa-edit fa-fw" /> AI Templates
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.QUICK_REPLIES_PAGE}
        path="/quick-replies"
      >
        <i className="fa fa-reply fa-fw" /> Quick Replies
      </ProtectedNavBarLink>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.ATTRIBUTES_PAGE}
        path="/attributes"
      >
        <i className="fa fa-table fa-fw" /> Attributes
      </ProtectedNavBarLink>

      {isMasterRole() && (
        <NavLink to="/access">
          <VerticalNavbarLink>
            <i className="fa fa-lock fa-fw" /> Access
          </VerticalNavbarLink>
        </NavLink>
      )}

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.ADMINS_PAGE}
        path="/admins"
      >
        <i className="fa fa-cogs fa-fw" /> Admins
      </ProtectedNavBarLink>

      <a href={'#'} onClick={() => openAnalytics()}>
        <VerticalNavbarLink>
          <i className="fa fa-chart-column fa-fw" /> Analytics
        </VerticalNavbarLink>
      </a>

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.VIRTUAL_AGENT_PAGE}
        path="/virtual-agent"
      >
        <i className="fa fa-robot fa-fw" /> Virtual Agent
      </ProtectedNavBarLink>

      {isCallIntelStandaloneEnabled && isInternalMode && (
        <NavLink to="/call-intel">
          <VerticalNavbarLink>
            <i className="fa fa-phone fa-fw" /> Call Intel
          </VerticalNavbarLink>
        </NavLink>
      )}

      <ProtectedNavBarLink
        accessSetting={ACCESS_SETTINGS.TOURS_PAGE}
        path="/tours"
      >
        <i className="fa fa-map fa-fw" /> Knock Tours
      </ProtectedNavBarLink>

      <Link to="/logout">
        <VerticalNavbarLink>
          <i className="fa fa-power-off fa-fw" /> Log out
        </VerticalNavbarLink>
      </Link>

      <SupportNavbarLink />
    </VerticalNavbar>
  );
};

export default NavigationBar;
