import React from 'react';
import PropTypes from 'prop-types';

import {
  Alert,
  Button,
  CardActions,
  makeStyles,
  NamedColors,
  Text,
} from '@knockrentals/knock-shared-web';

import { DripCampaignCard } from '../../../DripCampaignCard';
import PropertyList from "../../../../../../Components/PropertyList";
import FeatureCardContent from '../../../../common/FeatureCardContent';
import { useProperties } from './useProperties';
import { CONFIRMATION_MODES } from '../../constants';
import { SELECT_PROPERTY_MODES } from '../constants';

export const SUBHEADER_TEXT = {
  [SELECT_PROPERTY_MODES.ADD]: 'Select Properties',
  [SELECT_PROPERTY_MODES.EDIT]: 'Edit Properties',
};

const useStyles = makeStyles({
  selectCardActions: {
    '&.MuiCardActions-root': {
      marginLeft: 'auto',
    },
  },
  banner: {
    marginBottom: '12px',
    padding: '12px 16px'
  },
  messageText: {
    color: NamedColors.sky[900],
    lineHeight: '20px'
  }
});

const AI_NURTURE_INFO_TEXT = "Properties with AI Nurture Campaign enabled are not available for standard campaigns. Contact your representative to migrate an AI enabled property to a standard campaign."

const SelectProperties = ({
  associatedProperties,
  campaignId,
  closeDrawer,
  closeParentDrawer,
  selectPropertiesMode,
  setConfirmationMode,
  setErrorMessage,
  setIsEditing,
  triggerType,
  updateCampaign,
}) => {
  const classes = useStyles();

  const isEditingProperties =
    selectPropertiesMode === SELECT_PROPERTY_MODES.EDIT;

  const {
    getUpdatedAssociatedProperties,
    hasPropertySelectionChanged,
    propertiesList,
    selectedPropertiesCount,
    selectedPropertiesMap,
    setSelectedPropertiesMap,
  } = useProperties({
    associatedProperties,
    campaignId,
    selectPropertiesMode,
    setErrorMessage,
    triggerType,
  });

  const hasAiNurtureEnabledProperties = propertiesList.some(property => property.isAiNurtureEnabled);

  const handleSelectOnClick = () => {
    const associatedProperties = getUpdatedAssociatedProperties();

    updateCampaign({ associatedProperties });
    if (isEditingProperties) {
      setIsEditing(true);
    }
    closeDrawer();
  };

  const handleCancel = () => {
    hasPropertySelectionChanged
      ? setConfirmationMode(CONFIRMATION_MODES.CANCEL)
      : closeParentDrawer();
  };

  const handleBackButtonOnClick = () => {
    hasPropertySelectionChanged
      ? setConfirmationMode(CONFIRMATION_MODES.BACK)
      : closeDrawer();
  };

  return (
    <DripCampaignCard
      closeDrawer={handleCancel}
      handleBackButtonOnClick={handleBackButtonOnClick}
      subheader={SUBHEADER_TEXT[selectPropertiesMode]}
    >
      <FeatureCardContent>
        {hasAiNurtureEnabledProperties && (
          <Alert className={classes.banner} elevation={false} severity='info' aria-label='nurture-banner'>
            <Text className={classes.messageText} variant='caption'>
              {AI_NURTURE_INFO_TEXT}
            </Text>
          </Alert>
        )}
        <PropertyList
          propertiesList={propertiesList}
          selectedCount={selectedPropertiesCount}
          selectedPropertiesMap={selectedPropertiesMap}
          setSelectedPropertiesMap={setSelectedPropertiesMap}
        />
      </FeatureCardContent>

      <CardActions className={classes.selectCardActions}>
        <Button
          color="primary"
          disabled={!hasPropertySelectionChanged}
          onClick={handleSelectOnClick}
        >
          {isEditingProperties ? 'Continue' : 'Select'}
        </Button>
      </CardActions>
    </DripCampaignCard>
  );
};

SelectProperties.propTypes = {
  associatedProperties: PropTypes.arrayOf(
    PropTypes.shape({
      propertyId: PropTypes.number,
      propertyName: PropTypes.string,
    })
  ),
  campaignId: PropTypes.number,
  closeDrawer: PropTypes.func,
  closeParentDrawer: PropTypes.func,
  selectPropertiesMode: PropTypes.string,
  setConfirmationMode: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setIsEditing: PropTypes.func,
  triggerType: PropTypes.string,
  updateCampaign: PropTypes.func,
};

export default SelectProperties;
