import React from 'react';
import { KnockSVGLogo } from './KnockSVGLogo';
import {
  Box,
  Divider,
  NamedColors,
  Text,
  ThemeProvider,
  makeStyles,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  divider: {
    backgroundColor: NamedColors.slate[200],
  },
  knockAdminLogo: {
    alignItems: 'center',
    cursor: 'default',
    display: 'flex',
    gap: '16px',
    height: '32px',
    padding: '0 12px',
  },
  adminText: {
    color: NamedColors.slate[700],
    fontSize: '18px',
    fontWeight: '400',
  },
});

export const KnockAdminLogo = () => {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <Box className={classes.knockAdminLogo}>
        <KnockSVGLogo />
        <Divider className={classes.divider} flexItem orientation="vertical" />
        <Text className={classes.adminText}>Admin</Text>
      </Box>
    </ThemeProvider>
  );
};
