export const RECIPIENT = 'recipient';
export const CAMPAIGN_NAME = 'campaignName';
export const CAMPAIGN_TRIGGER = 'campaignTrigger';
export const DISABLED_PROPERTY_TOOLTIP_TEXT = "This property already has an active campaign for the selected trigger";
export const DISABLED_IMMEDIATE_ACTION_TOOLTIP_TEXT = 'Need to set at least one day delay for this campaign.'
export const AI_NURTURE_PROPERTY_TOOLTIP_TEXT = 'This property has AI Nurture enabled.'

export const CAMPAIGN_LABELS = {
  RECIPIENT: 'Recipient',
  CAMPAIGN_NAME: 'Campaign Name',
  CAMPAIGN_TRIGGER: 'Campaign Trigger',
};

export const RECIPIENT_TYPES = {
  PROSPECT: 'prospect',
  RESIDENT: 'resident',
};

export const TRIGGER_TYPES = {
  AI_EMAIL: 'ai_email',
  APPOINTMENT_CANCELLATION: 'appointment_cancellation',
  APPOINTMENT_CONFIRMATION: 'appointment_confirmation',
  APPOINTMENT_REMINDER: 'appointment_reminder',
  APPOINTMENT_CHANGED: 'appointment_changed',
  APPOINTMENT_PROPOSED: 'appointment_proposed',
  APPOINTMENT_REQUESTED: 'appointment_requested',
  CANCELLED_MANAGER: 'cancelled_manager',
  CANCELLED_RENTER: 'cancelled_renter',
  CONFIGURABLE_STALE: 'configurable_stale',
  NO_SHOW: 'no_show',
  POST_APPOINTMENT_MESSAGE: 'post_appointment_message',
  STALE_PROSPECT: 'stale_prospect',
};

export const TRIGGER_LABELS = {
  [TRIGGER_TYPES.AI_EMAIL]: 'AI Email Follow-Up',
  [TRIGGER_TYPES.APPOINTMENT_CANCELLATION]: 'Appointment Cancellation',
  [TRIGGER_TYPES.APPOINTMENT_CONFIRMATION]: 'Appointment Confirmation',
  [TRIGGER_TYPES.APPOINTMENT_REMINDER]: 'Appointment Reminder',
  [TRIGGER_TYPES.APPOINTMENT_CHANGED]: 'Appointment Changed',
  [TRIGGER_TYPES.APPOINTMENT_PROPOSED]: 'Appointment Proposed',
  [TRIGGER_TYPES.APPOINTMENT_REQUESTED]: 'Appointment Requested',
  [TRIGGER_TYPES.CANCELLED_MANAGER]: 'Property Appointment Cancellation',
  [TRIGGER_TYPES.CANCELLED_RENTER]: 'Prospect Appointment Cancellation',
  [TRIGGER_TYPES.CONFIGURABLE_STALE]: 'Nurture',
  [TRIGGER_TYPES.NO_SHOW]: 'Prospect Appointment No Show',
  [TRIGGER_TYPES.POST_APPOINTMENT_MESSAGE]: 'Appointment Follow-Up',
  [TRIGGER_TYPES.STALE_PROSPECT]: 'Stale Prospect',
};

export const RECIPIENT_BY_TRIGGER_TYPE = {
  [TRIGGER_TYPES.AI_EMAIL]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.APPOINTMENT_CANCELLATION]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.APPOINTMENT_CONFIRMATION]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.APPOINTMENT_REMINDER]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.APPOINTMENT_CHANGED]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.APPOINTMENT_PROPOSED]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.APPOINTMENT_REQUESTED]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.CANCELLED_MANAGER]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.CANCELLED_RENTER]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.CONFIGURABLE_STALE]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.NO_SHOW]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.POST_APPOINTMENT_MESSAGE]: RECIPIENT_TYPES.PROSPECT,
  [TRIGGER_TYPES.STALE_PROSPECT]: RECIPIENT_TYPES.PROSPECT,
};

export const PLACE_HOLDER_TEXT = {
  [RECIPIENT]: 'Select Recipient',
  [CAMPAIGN_TRIGGER]: 'Select Trigger',
};

export const SELECT_PROPERTY_MODES = {
  ADD: 'add',
  EDIT: 'edit',
};

export const TRIGGER_ACTION_DESC = {
  DEFAULT: 'Campaign Trigger',
  [TRIGGER_TYPES.AI_EMAIL]: 'the first AI Email response',
  [TRIGGER_TYPES.CONFIGURABLE_STALE]: 'the last prospect activity'
};
