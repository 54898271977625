import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import SettingsSwitch from './SettingsSwitch';
import SettingsTextInput from './SettingsTextInput';
import { useFeatureFlagContext } from '../../../Context/FeatureFlagContext';
import { usePropertyInfoSettings } from './use-property-info-settings';
import { IS_CHILD_PREFERENCE, PREFERENCE_NAMES } from './constants';
import './_PropertyInfoEditSettings.scss';

const {
  REQUIRE_LEASE_TERM,
  REQUIRE_PREFERRED_BEDROOM_COUNT,
  REQUIRE_PREFERRED_UNIT,
  REQUIRE_PROSPECT_FLOOR_PLAN,
  REQUIRE_PROSPECT_MOVE_IN_DATE,
  SELF_GUIDED_TOUR_BUTTON_LABEL,
  SELF_GUIDED_TOUR_URL,
  TOURS_EXPORT_ONLY_FAVORITE_UNIT,
} = PREFERENCE_NAMES;

const lroPreferencesSwitchFields = [
  REQUIRE_LEASE_TERM,
  REQUIRE_PREFERRED_BEDROOM_COUNT,
  REQUIRE_PREFERRED_UNIT,
];

const PropertyInfoEditSettings = ({
  propertyPreferences,
  savePropertyPreference,
  updatePropertyPreference,
}) => {
  const { isAdminLROSettingsEnabled } = useFeatureFlagContext();

  const {
    errorMessages,
    handleTextOnBlur,
    savePreference,
    textPreferences,
    updateErrorMessages,
    updateTextPreference,
  } = usePropertyInfoSettings({
    propertyPreferences,
    savePropertyPreference,
    updatePropertyPreference,
  });

  const handleOnBlur = (e) => {
    const { name } = e.target;
    handleTextOnBlur(name);
  };

  const handleTextOnChange = (e) => {
    const { name, value } = e.target;
    updateTextPreference({ [name]: value });
  };

  const handleSwitchOnChange = (e) => {
    const { checked, name } = e.target;
    savePreference(name, checked);
  };

  const resetErrorMessage = (e) => {
    const { name } = e.target;
    updateErrorMessages({ [name]: '' });
  };

  const preferenceSwitchFields = [
    ...(isAdminLROSettingsEnabled ? lroPreferencesSwitchFields : []),
    REQUIRE_PROSPECT_FLOOR_PLAN,
    REQUIRE_PROSPECT_MOVE_IN_DATE,
    TOURS_EXPORT_ONLY_FAVORITE_UNIT,
  ];

  return (
    <ThemeProvider>
      <div className="property-info-edit-settings">
        {preferenceSwitchFields.map((name) => {
          const preferences = IS_CHILD_PREFERENCE[name]
            ? propertyPreferences.preferences
            : propertyPreferences;
          return (
            <SettingsSwitch
              handleOnChange={handleSwitchOnChange}
              key={name}
              isChecked={preferences[name]}
              name={name}
            />
          );
        })}

        <SettingsTextInput
          errorMessages={errorMessages}
          handleOnBlur={handleOnBlur}
          handleOnChange={handleTextOnChange}
          name={SELF_GUIDED_TOUR_URL}
          onFocus={resetErrorMessage}
          value={textPreferences[SELF_GUIDED_TOUR_URL]}
        />

        <SettingsTextInput
          handleOnBlur={handleOnBlur}
          handleOnChange={handleTextOnChange}
          name={SELF_GUIDED_TOUR_BUTTON_LABEL}
          value={textPreferences[SELF_GUIDED_TOUR_BUTTON_LABEL]}
        />
      </div>
    </ThemeProvider>
  );
};

PropertyInfoEditSettings.propTypes = {
  propertyPreferences: PropTypes.shape({
    require_lease_term: PropTypes.bool,
    require_preferred_bedroom_count: PropTypes.bool,
    require_preferred_unit: PropTypes.bool,
    require_prospect_floorplan: PropTypes.bool,
    require_prospect_move_in_date: PropTypes.bool,
    preferences: PropTypes.shape({
      self_guided_tour_url: PropTypes.string,
      self_guided_tour_button_label: PropTypes.string,
      tours_export_only_favorite_unit: PropTypes.bool,
    }),
  }),
  savePropertyPreference: PropTypes.func,
  updatePropertyPreference: PropTypes.func,
};

export default PropertyInfoEditSettings;
