import { useRef, useState } from 'react';
import {
  getInitialTextPreferences,
  getOriginalPropertyPreferences,
  validate,
} from './utils';
import { IS_CHILD_PREFERENCE } from './constants';

export const usePropertyInfoSettings = ({
  propertyPreferences,
  savePropertyPreference,
  updatePropertyPreference,
}) => {
  const [textPreferences, setTextPreferences] = useState(
    getInitialTextPreferences(propertyPreferences.preferences)
  );
  const [errorMessages, setErrorMessages] = useState({});

  const originalPreferencesRef = useRef(
    getOriginalPropertyPreferences(propertyPreferences)
  );

  const updateTextPreference = (textUpdate) => {
    setTextPreferences((previousState) => ({
      ...previousState,
      ...textUpdate,
    }));
  };

  const updateErrorMessages = (errorMessages) => {
    setErrorMessages((previousState) => ({
      ...previousState,
      ...errorMessages,
    }));
  };

  const savePreference = async (settingName, value) => {
    const isChildPreference = IS_CHILD_PREFERENCE[settingName] || false;
    updatePropertyPreference(settingName, value, isChildPreference);

    try {
      await savePropertyPreference(settingName, value, isChildPreference);
      originalPreferencesRef.current = {
        ...originalPreferencesRef.current,
        [settingName]: value,
      };
    } catch {
      const previousValue = originalPreferencesRef.current[settingName];
      updatePropertyPreference(settingName, previousValue, isChildPreference);

      if (Object.getOwnPropertyDescriptor(textPreferences, settingName)) {
        updateTextPreference({ [settingName]: previousValue });
      }
    }
  };

  const handleValidation = (name, value) => {
    const errorMessages = validate(name, value);

    if (errorMessages) {
      updateErrorMessages(errorMessages);
    }
    return !errorMessages;
  };

  const handleTextOnBlur = (name) => {
    const value = textPreferences[name];
    if (value !== originalPreferencesRef.current[name]) {
      const isValid = handleValidation(name, value);
      if (!isValid) {
        return;
      }
      savePreference(name, value);
    }
  };

  return {
    errorMessages,
    handleTextOnBlur,
    savePreference,
    textPreferences,
    updateErrorMessages,
    updateTextPreference,
  };
};
