import { apiRemote, responseHandler } from '../../Services/RemoteService';

class PropertiesAPI {
  static getNearbyProperties(propertyId, radius) {
    let url = `property/${propertyId}/nearby-properties`;
    if (radius) url += `?radius=${radius}`;
    return apiRemote.get(url).then((response) => response.json());
  }

  static getLeasingTeams() {
    return apiRemote
      .get(`admin/properties/teams`)
      .then((response) => response.json());
  }

  static getCommunityInfo() {
    return apiRemote
      .get(`admin/properties`)
      .then((response) => {
        if (!response.ok || response.status !== 200) {
          throw new Error();
        }
        return response.json();
      })
      .catch((e) => {
        throw e;
      });
  }

  static createPropertyByCommunity(managerId, community) {
    return apiRemote
      .post(`admin/properties`, {
        community: community,
        owning_manager_id: managerId,
      })
      .then((response) => response.json());
  }

  static importProperties(importFormData) {
    return apiRemote
      .postFile(`admin/imports/properties`, importFormData)
      .then((response) => response.json());
  }

  static getPropertyById(propertyId) {
    return apiRemote
      .get(`admin/properties/${propertyId}`)
      .then((response) => response.json());
  }

  static async updateProperty(propertyId, updatedProperty) {
    const response = await apiRemote.put(`admin/properties/${propertyId}`, {
      community: updatedProperty.data,
      preferences: updatedProperty.preferences,
      custom_fees: updatedProperty.custom_fees,
    });
    if (response.status !== 201) {
      throw new Error();
    }

    return await response.json();
  }

  static async transferProperty(
    propertyId,
    leasingTeamPublicId,
    defaultManagerOwnerUsername
  ) {
    const response = await apiRemote.post(
      `admin/properties/${propertyId}/transfer`,
      {
        leasing_team_public_id: leasingTeamPublicId,
        default_manager_owner_username: defaultManagerOwnerUsername,
      }
    );
    if (!response.ok || response.status !== 200) {
      throw new Error();
    }
    return await response.json();
  }

  static getRelayEmail(email) {
    return apiRemote
      .get(`relay/email/${email}`)
      .then((response) => response.json());
  }

  static getAgentsByProperty = async (propertyIds) => {
    return apiRemote
      .post('admin/properties/users', {
        property_ids: propertyIds,
      })
      .then((response) => responseHandler(response));
  };
}

export default PropertiesAPI;
