import React from 'react';
import PropTypes from 'prop-types';

import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import {
  AmsiIntegrationInfo,
  AppfolioInfo,
  EngrainIntegrationInfo,
  EntrataIntegrationInfo,
  MriIntegrationInfo,
  OnsiteIntegrationInfo,
  RealPageIntegrationInfo,
  ResmanIntegrationInfo,
  YardiIntegrationInfo,
} from './IntegrationTypes';
import { IntegrationVendorNames } from './constants';

const PropertyIntegration = ({ integration }) => {
  const { isOSMEnabled, isToursSiteMapEnabled } = useFeatureFlagContext();

  if (!integration) {
    return null;
  }

  const { vendor } = integration;

  switch (vendor) {
    case IntegrationVendorNames.YARDI:
      return <YardiIntegrationInfo integration={integration} />;
    case IntegrationVendorNames.REALPAGE:
      return <RealPageIntegrationInfo integration={integration} />;
    case IntegrationVendorNames.ENTRATA:
      return <EntrataIntegrationInfo integration={integration} />;
    case IntegrationVendorNames.RESMAN:
      return <ResmanIntegrationInfo integration={integration} />;
    case IntegrationVendorNames.AMSI:
      return <AmsiIntegrationInfo integration={integration} />;
    case IntegrationVendorNames.APPFOLIO:
      return <AppfolioInfo integration={integration} />;
    case IntegrationVendorNames.ENGRAIN:
      return isToursSiteMapEnabled ? (
        <EngrainIntegrationInfo integration={integration} />
      ) : null;
    case IntegrationVendorNames.MRI:
      return <MriIntegrationInfo integration={integration} />;

    case IntegrationVendorNames.ONSITE:
      return isOSMEnabled ? (
        <OnsiteIntegrationInfo integration={integration} />
      ) : null;

    default:
      return null;
  }
};

PropertyIntegration.propTypes = {
  integration: PropTypes.object,
};

export default PropertyIntegration;
